import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getMe } from "../features/authSlice";
import { Tabs, Tab, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import ShowReservation from "./ShowReservation";

const Profil = () => {


  const [activeParentTab, setActiveParentTab] = useState(0);
  // const [user, setUser] = useState("");
  const [activeChildTab, setActiveChildTab] = useState(0);
  const [showInfo, setShowInfo] = useState(false);
  const [showReservations, setShowReservations] = useState(false);
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [born_date_user , setBirthDateUser] = useState(new Date());
  const [phone_number_portable, setPortable] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zip_code, setZip] = useState("");
  const [role, setRole] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { uuid } = useParams();

  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  const handleParentTabChange = (event, newValue) => {
    setActiveParentTab(newValue);
  };

  const handleChildTabChange = (event, newValue) => {
    setActiveChildTab(newValue);
  };


 

  const updateProfileByUuid = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(`https://dev.ninoloc.fr/api/profil/update/${uuid}`, {
        name: name,
        firstname: firstname,
        email: email,
        born_date_user : born_date_user,
        phone_number_portable: phone_number_portable,
        country: country,
        city: city,
        zip_code: zip_code,
      });
      console.log(response)
      navigate("/dashboard");
    } catch (error){
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };



  useEffect(() => {
    const getUserByUuid = async () => {
      try {
        const response = await axios.get(`https://dev.ninoloc.fr/api/profil/${uuid}`);
        setName(response.data.name);
        setFirstname(response.data.firstname);
        setEmail(response.data.email);
        setBirthDateUser(response.data.born_date_user);
        setPortable(response.data.phone_number_portable);
        setCountry(response.data.country);
        setCity(response.data.city);
        setZip(response.data.zip_code);
      } catch (error) {
        if (error.response) {
          navigate("/404");
          setMsg(error.response.data.msg);
        }
      }
    };
    getUserByUuid();
  }, [uuid]);

const resetPassword = () => {
  navigate(`/reset-password/${user.uuid}`)
}

  return (

    <div className="container is-fluid mt-6">
      <div className="container is-flex is-fluid is-justify-content-center">
        <p className="subtitle is-size-2 ">
        </p>
      </div>
      <div className="container is-fluid">
        <div>
          <Tabs
            value={activeParentTab}
            onChange={handleParentTabChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab className="is-size-4" label="Mes informations" />
            <Tab className="is-size-4" label="Mes réservations" />
          </Tabs>

          {activeParentTab === 0 && (
            <div className="">
              <div className="columns">
                <div className="column">
                  <div className="box  is-flex is-flex-direction-column mt-6 ">
                    <div className="subtitle">Identité</div>
                    <div className="container">
                      <div className="container is-flex is-justify-content-space-between">
                        <div className="field mr-4">
                          <label className="label">Prénom</label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                            ></input>
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">Nom</label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              value={name}
                               onChange={(e) => setName(e.target.value)}
                            ></input>
                          </div>
                        </div>
                      </div>

                      <div className="container is-flex is-justify-content-space-between">
                        <div className="field">
                          <label className="label">Date de naissance</label>
                          <div className="control">
                            <input
                              className="input"
                              type="date"
                              value={born_date_user}
                              onChange={(e) => setBirthDateUser(e.target.value)}

                            ></input>
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">Téléphone</label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              value={phone_number_portable}
                              onChange={(e) => setPortable(e.target.value)}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="container is-flex is-justify-content-space-between">
                        <div className="field">
                          <label className="label">Nationalité</label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                            ></input>
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">Ville</label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="container is-flex is-justify-content-space-between is-align-items-flex-end">
                        <div className="field">
                          <label className="label">Code postal</label>
                          <div className="control">
                            <input
                              className="input"
                              type="number"
                              value={zip_code}
                              onChange={(e) => setZip(e.target.value)}
                            ></input>
                          </div>
                        </div>
                        <div class="control">
                          <button class="button is-primary" onClick={updateProfileByUuid}>Enregister</button>
                        </div>

                      </div>
                      <div className="erro">{msg}</div>

                    </div>
                  </div>
                </div>

                <div className="column">
                  <div className="box  is-flex is-flex-direction-column mt-6 ">
                  <div className="subtitle">Connexion</div>
                    <div className="container">
                      <div className="field">
                        <label className="label">Mot de passe</label>
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            placeholder="Text input"
                            disabled="disabled"
                            value="•••••••••••"
                          ></input>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Email</label>
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            disabled="disabled"
                            placeholder="Text input"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          ></input>
                        </div>
                      </div>
                      <button class="button" onClick={resetPassword}>Changer de mot de passe</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeParentTab === 1 && (
            <ShowReservation></ShowReservation>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profil;
