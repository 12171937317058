import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AllReservations = () => {
  const [reservations, setReservations] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredReservations, setFilteredReservations] = useState([]);

  const getReservations = async () => {
    try {
      const response = await axios.get('https://dev.ninoloc.fr/api/reservations/admin');
      setReservations(response.data);
    } catch (error) {
      console.log('Impossible de récupérer les réservations', error);
    }
  };

  useEffect(() => {
    getReservations();
  }, []);

  const handleArchive = async (reservation) => {
    try {
      await axios.put('https://dev.ninoloc.fr/api/reservation/archived', {
        id: reservation.id,
        archived: !reservation.archived
      });
      // Rechargez les réservations pour refléter l'état d'archivage mis à jour
      getReservations();
    } catch (error) {
      console.log('Erreur lors de la mise à jour de l\'état d\'archivage', error);
    }
  };



  useEffect(() => {
    const filterReservations = () => {
      const filtered = reservations.filter((reservation) =>
        reservation.referenceCommande.toLowerCase().includes(searchValue.toLowerCase())
      );
      // Trie les réservations par date de création, du plus récent au moins récent
      const sortedReservations = filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setFilteredReservations(sortedReservations);
    };

    filterReservations();
  }, [searchValue, reservations]);

  return (
    <div>
      <div className="container is-fluid">
      <div className="title">Toutes les réservations</div>
        <div>Rechercher par référence :</div>
      <input
          type="text"
          className="input mb-6"
          placeholder="Rechercher par référence de commande..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>N°</th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Email</th>
            <th>Date de départ</th>
            <th>Heure de départ</th>
            <th>Date d'arrivé</th>
            <th>Heure d'arrivé</th>
            <th>Model véhicule</th>
            <th>Total</th>
            <th>Status</th>
            <th>Reference</th>
            <th>Archiver</th>

          </tr>
        </thead>
        <tbody>
          {filteredReservations.map((reservation, index) => (
            <tr className={reservation.archived ? "has-background-grey-light" : ""} key={reservation.id}>
            <td>{index + 1}</td>
            <td>{reservation.user.name}</td>
            <td>{reservation.user.firstname}</td>
            <td>{reservation.user.email}</td>
            <td>{reservation.startDate}</td>
            <td>{reservation.startTime}</td>
            <td>{reservation.endDate}</td>
            <td>{reservation.endTime}</td>
            <td>{reservation.vehicule_name}</td>
            <td>{reservation.totalAmount}€</td>
            <td>{reservation.paymentStatus}</td>
            <td>{reservation.referenceCommande}</td>
            <td>
            <button className={reservation.archived ? "button is-normal is-danger " : "button is-normal is-info"} onClick={() => handleArchive(reservation)}>
          {reservation.archived ? 'Désarchiver' : 'Archiver'}
        </button>
            </td>
          </tr>
          ))}
        </tbody>
      </table>
    </div>   
    </div>
      

  );
};

export default AllReservations;
