import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import moment from "moment";
import DatePicker from "react-datepicker";
import "../assets/styles/ReservationForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import TimePicker from "react-time-picker";
import axios from "axios";
import VehicleCard from "./VehicleCard";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../features/authSlice";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Marquee from "react-marquee-slider";
import CalendarCheck from "./CalendarCheck";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import { faGasPump } from "@fortawesome/free-solid-svg-icons";
import { faSnowflake } from "@fortawesome/free-solid-svg-icons";
import { faPerson } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import Gear from "../assets/images/gear.png";
import ReservationDetails from "./ReservationDetails";

moment.locale("fr");

registerLocale("fr", fr);

setDefaultLocale("fr");

const CalendarAndReservation = () => {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [type_fuel, setFuel] = useState("");
  const [number_places, setNumberPlace] = useState("");
  const [age_drive_license, setLicense] = useState("");
  const [animals, setAnimals] = useState("");
  const [pictures, setPictures] = useState("");
  const [gear_type, setGear] = useState("");
  const [number_doors, setDoors] = useState("");
  const [air_conditioning, setAir] = useState("");
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [volume, setVolume] = useState("");
  const [category, setCategory] = useState("");
  const [msg, setMsg] = useState("");
  const [vehicule, setVehicule] = useState([]);
  const [numberOfDays, setNumberOfDays] = useState(null);
  const [result, setResult] = useState("");
  const [extraKms, setExtraKms] = useState(0); // Ajoutez cette ligne
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [isVehicleAvailable, setIsVehicleAvailable] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [agency, setAgency] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [nextAvailableDate, setNextAvailableDate] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [days, setDays] = useState(0);

  const dispatch = useDispatch();
  const { user, isError, isSuccess, isLoading, message, phone_number } =
    useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  const login = () => {
    navigate("/login");
  };

  const register = () => {
    navigate("/register");
  };

  useEffect(() => {
    calculTotalAmount();
  }, [days, price, extraKms]);

  console.log(user);
  const calculTotalAmount = () => {
    if (days) {
      const kmsCost = extraKms * 1; // Ici, on multiplie les kilomètres supplémentaires par leur coût unitaire (1€ par exemple)
      const ammount = days * price + kmsCost;
      setResult(ammount);
      return ammount;
    }
    return 0;
  };

  const getVehicule = async () => {
    const response = await axios.get("https://dev.ninoloc.fr/api/vehicules");
    setVehicule(response.data);
  };

  useEffect(() => {
    getVehicule();
  }, []);
  useEffect(() => {
    const getVehiculeByUuid = async () => {
      try {
        const response = await axios.get(
          `https://dev.ninoloc.fr/api/vehicules/reservation/${uuid}`
        );
        setId(response.data.id);
        setName(response.data.name);
        setPrice(response.data.price);
        setDescription(response.data.description);
        setFuel(response.data.type_fuel);
        setNumberPlace(response.data.number_places);
        setLicense(response.data.age_drive_license);
        setAnimals(response.data.animals);
        setPictures(response.data.pictures);
        setGear(response.data.gear_type);
        setDoors(response.data.number_doors);
        setAir(response.data.air_conditioning);
        setHeight(response.data.height);
        setWidth(response.data.width);
        setVolume(response.data.volume);
        setCategory(response.data.category);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getVehiculeByUuid();
  }, [uuid]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      console.log("L'utilisateur n'est pas connecté.");
      setShowModal(true);
      return;
    } else if (!user.is_verified) {
      setShowModal(true);
      return;
    }

    try {
      const response = await axios.post(
        `https://dev.ninoloc.fr/api/reservations/checkvehicule/${uuid}`,
        {
          startDate: startDate,
          endDate: endDate,
          startTime: startTime,
          endTime: endTime,
          agency: agency,
        }
      );
      setIsVehicleAvailable(response.data.isVehicleAvailable);
      setNextAvailableDate(response.data.nextAvailableDate);
    } catch (error) {}
  };

  const today = moment().toDate();

  useEffect(() => {
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const diff = end.diff(start, "days");
      const numberOfDays = Math.abs(diff + 1);
      setDays(numberOfDays);
    } else {
      setDays(0);
    }
  }, [startDate, endDate]);

  return (
    <div className="div">
      <div className="">
        <form onSubmit={handleSubmit} autocomplete="off">
          <div className="is-flex is-flex-direction-column mt-5">
            <label>Choisissez une agence:</label>
            <div className="select is-normal">
              <select
                required
                value={agency}
                onChange={(event) => setAgency(event.target.value)}
                className="form-control"
              >
                <option value="" disabled>
                  Choisissez une agence
                </option>
                <option value="Loudéac">Loudéac</option>
              </select>
            </div>
          </div>
          <div className="is-flex is-justify-content-space-between is-align-content-center mt-5">
            <div className="form-group">
              <label>Date de début:</label>
              <div className="" id="">
                <DatePicker
                  required
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd/MM/yyyy"
                  locale="fr"
                  className="input"
                  id="inputDatePicker"
                  portalId="root-portal"
                  minDate={today}
                />
              </div>
            </div>
            <div className="is-flex is-align-items-center mt-5">
              <div className="container">
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="icon_arrow"
                ></FontAwesomeIcon>
              </div>
            </div>
            <div className="form-group">
              <label>Date de fin:</label>
              <div className="control">
                <DatePicker
                  required
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="dd/MM/yyyy"
                  locale="fr"
                  className="input"
                  portalId="root-portal"
                  id="inputDatePicker"
                />
              </div>
            </div>
          </div>

          <div className="is-flex is-justify-content-space-between is-align-content-center mt-5">
            <div className="form-group">
              <label>Heure de départ:</label>
              <div className="select" id="inputDatePicker">
                <select
                  className=""
                  value={startTime}
                  onChange={(event) => setStartTime(event.target.value)}
                  name="Heure_de_départ"
                  required
                >
                  <option selected disabled value="">
                    Heure de départ
                  </option>
                  <option value="8:00:00">8:00</option>
                  <option value="9:00:00">9:00</option>
                  <option value="10:00:00">10:00</option>
                  <option value="11:00:00">11:00</option>
                  <option value="12:00:00">12:00</option>
                  <option value="13:00:00">13:00</option>
                  <option value="14:00:00">14:00</option>
                  <option value="15:00:00">15:00</option>
                  <option value="16:00:00">16:00</option>
                  <option value="17:00:00">17:00</option>
                  <option value="18:00:00">18:00</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <label>Heure d'arrivé</label>
              <div className="select" id="">
                <select
                  className=""
                  value={endTime}
                  onChange={(event) => setEndTime(event.target.value)}
                  name="Heure_darrivé"
                  required
                >
                  <option selected disabled value="">
                    Heure d'arrivé
                  </option>
                  <option value="8:00:00">8:00</option>
                  <option value="9:00:00">9:00</option>
                  <option value="10:00:00">10:00</option>
                  <option value="11:00:00">11:00</option>
                  <option value="12:00:00">12:00</option>
                  <option value="13:00:00">13:00</option>
                  <option value="14:00:00">14:00</option>
                  <option value="15:00:00">15:00</option>
                  <option value="16:00:00">16:00</option>
                  <option value="17:00:00">17:00</option>
                  <option value="18:00:00">18:00</option>
                </select>
              </div>
            </div>
          </div>

          <div className="is-flex  is-align-items-center is-justify-content-space-between mt-5">
            <div className="form-group">
              <p>Nombre de jours: {days}</p>
            </div>

            <div className="form-group">
              <button type="submit" className="btn btn-primary">
                Reserver
              </button>
            </div>
          </div>

          {showModal && (
            <div className="modal is-active">
              <div
                className="modal-background"
                onClick={() => setShowModal(false)}
              ></div>
              <div className="modal-card">
                <header className="modal-card-head">
                  <p className="modal-card-title">Information</p>
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={() => setShowModal(false)}
                  ></button>
                </header>
                <section className="modal-card-body">
                  {!user && <p>Veuillez vous connecter avant de réserver.</p>}
                  {user && !user.is_verified && (
                    <p>Veuillez vérifier votre compte avant de réserver.</p>
                  )}
                  {user && user.is_verified && isSuccess && (
                    <p>Réservation réussie. Merci de votre confiance !</p>
                  )}
                </section>

                <section className="modal-card-body">
                  <button className="button" onClick={login}>
                    Se connecter
                  </button>
                  <button className="button" onClick={register}>
                    S'inscrire
                  </button>
                </section>

                <footer className="modal-card-foot">
                  <button
                    className="button"
                    onClick={() => setShowModal(false)}
                  >
                    OK
                  </button>
                </footer>
              </div>
            </div>
          )}
        </form>
        {isVehicleAvailable === true &&
          navigate(`/reservation/information-conducteur/${uuid}`, {
            state: {
              agency,
              startDate,
              endDate,
              id,
              endTime,
              startTime,
              name,
              pictures,
              extraKms,
              result,
            },
          })}
        {isVehicleAvailable === false && (
          <p>Le véhicule n'est pas disponible avant le {nextAvailableDate}.</p>
        )}
      </div>
      <div className="container is-fluid">
        <div className="title">{name}</div>
        <div className="columns">
          <div className="column">
            <img
              src={`https://dev.ninoloc.fr/uploads/${pictures}`}
              alt={`image ${name}`}
            />
          </div>
          <div className="column"></div>
        </div>
        <div className="container">
          <div className="subtitle">prix / jour : {price}</div>
          <div className="">Total: {result}€</div>

          <div>
            <div>
              <p>
                Date de début :{" "}
                {startDate ? startDate.toLocaleDateString("fr-FR") : "N/A"}
              </p>
              <p>
                Date de fin :{" "}
                {endDate ? endDate.toLocaleDateString("fr-FR") : "N/A"}
              </p>
              <p>heure de depart : {startTime}h</p>
              <p>heure d'arriver : {endTime}h</p>
              <p>agence : {agency}</p>
            </div>

            <label htmlFor="extra-kms">
              Kilomètres supplémentaires: {extraKms} km
            </label>

            <input
              type="range"
              id="extra-kms"
              value={extraKms}
              onChange={(e) => setExtraKms(Number(e.target.value))}
              min="0"
              max="500" // Choisissez la valeur maximale en fonction de vos besoins
              step="10" // Choisissez l'intervalle des valeurs en fonction de vos besoins
            />
          </div>
        </div>
        <div className="title">Description</div>
        <div className="columns">
          <div className="column">
            <p>{description}</p>
          </div>
          <div className="column">
            <img
              src={`https://dev.ninoloc.fr/uploads/${pictures}`}
              alt={`image ${name}`}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <img
              src={`https://dev.ninoloc.fr/uploads/${pictures}`}
              alt={`image ${name}`}
            />
          </div>
          <div className="column">
            <p>{id}</p>
          </div>
        </div>
        <div className="container">
          <div className="title">Autres véhicule</div>
          <div className="container is-flex">
            {vehicule.map((vehicule) => (
              <div key={vehicule.id}>
                <div className="card">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img
                        src={`https://dev.ninoloc.fr/uploads/${vehicule.pictures}`}
                        alt={`image ${vehicule.name}`}
                      />
                    </figure>
                  </div>
                  <div className="card-content">
                    <h1 className="title">{vehicule.name}</h1>
                    <h2 className="subtitle has-text-weight-bold">
                      à partir de {vehicule.price} € / jours
                    </h2>
                    <div className="is-flex is-justify-content-center mb-6">
                      <Link to={`/vehicules/reservation/${vehicule.uuid}`}>
                        <button className="button">Réserver ce véhicule</button>
                      </Link>
                    </div>
                    <div className="columns">
                      <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center ">
                        <FontAwesomeIcon
                          icon={faPerson}
                          className="icon_card"
                        />
                        {vehicule.number_places}
                      </div>
                      <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                        <FontAwesomeIcon
                          icon={faGasPump}
                          className="icon_card"
                        />
                        {vehicule.type_fuel}
                      </div>
                      <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                        <FontAwesomeIcon
                          icon={faIdCard}
                          className="icon_card"
                        />
                        {vehicule.age_drive_license}
                      </div>
                      <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                        <img src={Gear} alt="" className="icon_card" />
                        {vehicule.gear_type}
                      </div>
                      <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                        <FontAwesomeIcon
                          icon={faSnowflake}
                          className="icon_card"
                        />
                        {vehicule.air_conditioning}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarAndReservation;
