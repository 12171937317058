import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getMe } from "../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const ReservationDetails = () => {
  const { state } = useLocation();
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [born_date_user, setBirthDateUser] = useState(new Date());
  const [phone_number_portable, setPortable] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zip_code, setZip] = useState("");
  const { uuid } = useParams();
  const [msg, setMsg] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProfileByUuid();
  };
  
  const updateProfileByUuid = async (e) => {
    // e.preventDefault();
    const uuid = user.uuid;
    try {
      const response = await axios.patch(
        `https://dev.ninoloc.fr/api/profil/update/${uuid}`,
        {
          name: name,
          firstname: firstname,
          email: email,
          born_date_user: born_date_user,
          phone_number_portable: phone_number_portable,
          country: country,
          city: city,
          zip_code: zip_code,
        }
      );
      console.log(response);
      const updatedUserResponse = await axios.get(
        `https://dev.ninoloc.fr/api/profil/${uuid}`
      );
      const updatedUserData = updatedUserResponse.data;
      navigate(`/reservation/payement/${uuid}`, { state: { reservationData: updatedUserData, vehicleData: state } });
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  useEffect(() => {
    const getUserByUuid = async () => {
      const uuid = user.uuid;
      try {
        const response = await axios.get(
          `https://dev.ninoloc.fr/api/profil/${uuid}`
        );
        setName(response.data.name);
        setFirstname(response.data.firstname);
        setEmail(response.data.email);
        setBirthDateUser(response.data.born_date_user);
        setPortable(response.data.phone_number_portable);
        setCountry(response.data.country);
        setCity(response.data.city);
        setZip(response.data.zip_code);
        setRole(response.data.role);
      } catch (error) {
        if (error.response) {
          navigate("/404");
          setMsg(error.response.data.msg);
        }
      }
    };
    getUserByUuid();
  }, [uuid]);



  return (
    <div className="container is-fluid">
      <div className="container">
        <div className="title">Informations vehicule</div>
        <div className="box">
          <div className="container"></div>
          <div className="container">
            <p>Model : {state.name}</p>
            <p>Agence : {state.agency}</p>
            <p>
              date de departs : {state.startDate.toLocaleDateString("fr-FR")}
            </p>
            <p>date de retour : {state.endDate.toLocaleDateString("fr-FR")}</p>
            <p>Heure de depart : {state.startTime} heures</p>
            <p>Heure de retour : {state.endTime} heures</p>
            <p>Total: {state.result}</p>
            <p>Killometre supp : {state.extraKms}</p>
          </div>
        </div>
      </div>
      <div className="container mt-6">
        <div className="">
          <div className="columns">
            <div className="column ">
              <form onSubmit={handleSubmit}>
                <div className="box  is-flex is-flex-direction-column mt-6 ">
                  <div className="subtitle">Informations Conducteur</div>
                  <div className="container">
                    <div className="container is-flex is-justify-content-space-between">
                      <div className="field mr-4">
                        <label className="label">Prénom</label>
                        <div className="control">
                          <input
                            required
                            className="input"
                            type="text"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                          ></input>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Nom</label>
                        <div className="control">
                          <input
                            required
                            className="input"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>

                    <div className="container is-flex is-justify-content-space-between">
                      <div className="field">
                        <label className="label">Date de naissance</label>
                        <div className="control">
                          <input
                            required
                            className="input"
                            type="date"
                            value={born_date_user}
                            onChange={(e) => setBirthDateUser(e.target.value)}
                          ></input>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Téléphone</label>
                        <div className="control">
                          <input
                            required
                            className="input"
                            type="text"
                            value={phone_number_portable}
                            onChange={(e) => setPortable(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="container is-flex is-justify-content-space-between">
                      <div className="field">
                        <label className="label">Nationalité</label>
                        <div className="control">
                          <input
                            required
                            className="input"
                            type="text"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          ></input>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">Ville</label>
                        <div className="control">
                          <input
                            required
                            className="input"
                            type="text"
                            value={user.city}
                            onChange={(e) => setCity(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="container is-flex is-justify-content-space-between is-align-items-flex-end">
                      <div className="field">
                        <label className="label">Code postal</label>
                        <div className="control">
                          <input
                            className="input"
                            type="number"
                            value={zip_code}
                            onChange={(e) => setZip(e.target.value)}
                            required
                          ></input>
                        </div>
                      </div>
                      <div class="control">
                        <button type="submit" class="button is-primary">
                          Valider
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationDetails;
