import React from 'react'
import ResetPasswordMail from '../components/ResetPasswordMail'
import Layout from './Layout'

const ResetPasswordMailPage = () => {
  return (
    <Layout>
        <ResetPasswordMail></ResetPasswordMail>
    </Layout>
  )
}

export default ResetPasswordMailPage