import React, {useEffect} from 'react'
import VehiculeList from '../components/VehiculeList'
import Layout from './Layout'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import FiltreVehicule from '../components/FiltreVehicule';
import BannerVerification from "../components/BannerVerification";




const Vehicules = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isError } = useSelector((state) => state.auth);
  
    useEffect(() => {
      dispatch(getMe());
    }, [dispatch]);
  
    // useEffect(() => {
    //   if (isError) {
    //     navigate("/");
    //   }
    // }, [isError, navigate]);

  return (
    <Layout>
      <BannerVerification></BannerVerification>
        <VehiculeList>
          <FiltreVehicule></FiltreVehicule>

        </VehiculeList>
    </Layout>
  )
}

export default Vehicules