import React from 'react'
import Layout from './Layout'
import VerificationCode from '../components/VerificationCode'

const Verification = () => {
  return (
    <Layout>
        <VerificationCode></VerificationCode>
    </Layout>
  )
}

export default Verification