import React from 'react'
import Layout from './Layout'
import QuiSommeNousC from '../components/QuiSommeNousC'
import BannerVerification from "../components/BannerVerification";


const QuiSommeNous = () => {
  return (
    <Layout>
        <BannerVerification></BannerVerification>
        <QuiSommeNousC></QuiSommeNousC>
    </Layout>
  )
}

export default QuiSommeNous