import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import countries from "./countries.json";
import { GeoapifyGeocoderAutocomplete, GeoapifyContext } from '@geoapify/react-geocoder-autocomplete'
import DatePicker from "react-datepicker";
import '@geoapify/geocoder-autocomplete/styles/minimal.css'

const FormEditUser = () => {
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [born_date_user , setBirthDateUser] = useState(new Date());
  const [phone_number_portable, setPortable] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zip_code, setZip] = useState("");
  const [role, setRole] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const updateUser = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`https://dev.ninoloc.fr/api/users/${id}`, {
        name: name,
        firstname: firstname,
        email: email,
        born_date_user : born_date_user,
        phone_number_portable: phone_number_portable,
        password: password,
        confPassword: confPassword,
        country: country,
        city: city,
        zip_code: zip_code,
        role: role,
      });
      navigate("/users");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  useEffect(() => {
    const getUserById = async () => {
      try {
        const response = await axios.get(`https://dev.ninoloc.fr/api/users/${id}`);
        setName(response.data.name);
        setFirstname(response.data.firstname);
        setEmail(response.data.email);
        setBirthDateUser(response.data.born_date_user);
        setPortable(response.data.phone_number_portable);
        setCountry(response.data.country);
        setCity(response.data.city);
        setZip(response.data.zip_code);
        setRole(response.data.role);


      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getUserById();
  }, [id]);



  return (
    <div>
         <h1 className='title'>Users</h1>
        <h2 className='subtitle'>update user</h2>
        <div className="card is-shadowless">
            <div className="card-content">
                <div className="content">
                <form onSubmit={updateUser} >
                      <div className="field">
                <label className="label">Nom</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Prénom</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Name"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>


              <div className="field">
                <label className="label">Date de naissance</label>
                <div className="control">


                <DatePicker selected={born_date_user} onChange={(e) => setBirthDateUser(e.target.value)} />



                  {/* <input
                    type="date"
                    max="01/01/1900"
                    className="input"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setBirthDateUser(e.target.value)}
                  /> */}
                </div>
              </div>

              <div className="field">
                <label className="label">Téléphone portable</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder=""
                    value={phone_number_portable}
                    onChange={(e) => setPortable(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Pays</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option value="">Selectionner un pays</option>
                      {countries.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
              <label className="label">Ville</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Ville"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Code postal</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Ville"
                    value={zip_code}
                    onChange={(e) => setZip(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Password</label>
                <div className="control">
                  <input
                    type="password"
                    className="input"
                    placeholder="*****"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label"> confirme Password</label>
                <div className="control">
                  <input
                    type="password"
                    className="input"
                    placeholder="*****"
                    value={confPassword}
                    onChange={(e) => setConfPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label"> role</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option value="admin">Admin</option>
                      <option value="user">User</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <p className="has-text-centered">{msg}</p>

                <div className="control">
                  <button
                    type="submit"
                    className="button is-success is-fullwidth"
                  >
                    Mise à jour
                  </button>
                </div>
              </div>
              </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FormEditUser