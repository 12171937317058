import React from "react";

const Error404= () => {
  return (
      <div className="container is-flex is-fluid is-flex-direction-column is-align-items-center">
        <div className="title">Erreur 404</div>
        <div className="subtitle">
          Désolé, la page que vous recherchez est introuvable.
        </div>
      </div>
  );
};

export default Error404;
