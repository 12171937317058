import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ImageNosVehicules from "../assets/images/ImageNosVehicule.jpg";
import '../assets/styles/StyleNosVehicules.css'

const ButtonNosVehicules = () => {


  const navigate = useNavigate()

  const NosVehicules = () => {
    navigate("/vehicules");
  };

  return (
  <div className=""  onClick={NosVehicules} >
     <div className="container has-background-grey-lighter is-flex is-flex-direction-column  is-clickable is-flex-wrap-wrap " id="containerButtonNosVehicule">
      <div className="container">
        <div className="subtitle mt-5" id="fontButton">Nos véhicules</div>
      </div>
      <div className="container">
        <figure class="image" id="imageNosVehicules">
          <img src={ImageNosVehicules} alt="ImageNosVehicule" />
        </figure>
      </div>
    </div>
  </div>
   
  );
};

export default ButtonNosVehicules;
