import React from "react";
import logoNinoloc from "../assets/images/logoNinoloc.png";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import "../assets/styles/footer.css";
import NinolocReviews from "./RateNinolocGoogle";

const Footer = () => {
  return (
    <footer class="footer">
      <div className="content has-text-centered">
        <div className="columns">
          <div className="column is-4">
            <div className="container is-flex is-flex-direction-column">
            <figure className="image is-128x128">
                <img src={logoNinoloc}/>
                </figure>
              <div className="container is-flex is-flex-direction-column is-align-items-flex-start">
                <p className="is-size-4">A propos</p>
                <p className="has-text-left">
                  Ninoloc est une société de location de véhicules basée à
                  Loudéac, en France. Elle propose une gamme de véhicules de
                  tourisme et utilitaires de bonne qualité à des prix
                  compétitifs.
                </p>
              </div>
              
            </div>
          </div>
          <div className="column">
            <div className="container is-flex is-flex-direction-column is-align-items-flex-start">
              <p className="subtitle">Plan du site :</p>
              <NavLink className="has-text-black" to="/dashboard">
                Accueil
              </NavLink>
              <NavLink className="has-text-black" to="/vehicules">
                Nos véhicules
              </NavLink>

              <NavLink className="has-text-black" to="/qui-somme-nous">
                Qui somme-nous ?
              </NavLink>
              <NavLink className="has-text-black" to="/login">
                Se connecter
              </NavLink>
              <NavLink className="has-text-black" to="/register">
                S'inscrire
              </NavLink>
              <NavLink className="has-text-black" to="/profil">
                Votre profil
              </NavLink>
            </div>
          </div>
          <div className="column">
            <div className="container is-flex is-flex-direction-column  is-align-items-flex-start">
              <p className="subtitle">Contact</p>
              <a href="mailto:">commercial@ninoloc.fr</a>
              <a href="tel:0782024621">0782024621</a>
            </div>
          </div>
          <div className="column">
            <div className="container is-flex is-flex-direction-column is-align-items-flex-start">
              <p className="subtitle">Juridiction</p>
              <NavLink className="has-text-black" to="/mentions-legales">
                Mentions légales
              </NavLink>
              <NavLink className="has-text-black" to="/conditions-generales">
                Conditions générales d'utilisation
              </NavLink>
              <NavLink
                className="has-text-black"
                to="/politique-de-confidentialite"
              >
                Politique de confidentialité
              </NavLink>

              {/* faire ouverture gestion des cookies */}
              <NavLink className="has-text-black" to="/cookies">
                Cookies
              </NavLink>
            </div>
          </div>
          <div className="column">
            <div className="container">
              <p className="subtitle">Réseaux Sociaux</p>
              <a href="https://www.facebook.com/Ninoloc-108291798294155/">
                <FontAwesomeIcon
                  icon={faFacebookF}
                  className="icon-footer"
                ></FontAwesomeIcon>
              </a>
              <a href="">
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="icon-footer"
                ></FontAwesomeIcon>
              </a>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
