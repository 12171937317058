import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

const PUBLIC_KEY =
  "pk_test_51Mxol0CIBB255rUghF9Qa22M7huhTLXiYlRu8oDaaHDlkQglgeFg6zcLQvA1JulBiWK3veBPZoIo36PFWCtCSd1w001lwWHfKh";
const stripePromise = loadStripe(PUBLIC_KEY);

// startTime = {vehicleData.startTime}
// endTime = {vehicleData.endTime}
// startDate = {vehicleData.startDate.toLocaleDateString("fr-FR")}
// endDate = {vehicleData.endDate.toLocaleDateString("fr-FR")}

const Stripe = ({ totalAmount , email , name, vehiculeId , startTime, endTime, startDate, endDate,  vehicule_name}) => {
  return (
    <div className="container">
      <h1>Payement</h1>
      <Elements stripe={stripePromise}>
        <CheckoutForm totalAmount={totalAmount} email={email} name={name} vehiculeId={vehiculeId} startDate={startDate} startTime = {startTime} endTime={endTime} endDate={endDate} vehicule_name={vehicule_name}></CheckoutForm>
      </Elements>
    </div>
  );
};

export default Stripe;
