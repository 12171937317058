import React, {useEffect} from 'react'
import Layout from './Layout'
import FinalReservation from '../components/FinalReservation'



const RemerciementPage = () => {
   
  return (
    <Layout>
        <FinalReservation></FinalReservation>
    </Layout>
  )
}

export default RemerciementPage