import React from 'react'
import Layout from './Layout';
import ForgotPassword from '../components/ForgotPassword';

const ForgotPasswordPage = () => {
  return (

    <Layout>
        <ForgotPassword></ForgotPassword>
    </Layout>
  )
}

export default ForgotPasswordPage