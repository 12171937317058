import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ResetPasswordMail = () => {

  const { token } = useParams(); // Récupérer le jeton de l'URL
  const navigate = useNavigate();


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);




  useEffect(() => {
    // Vérifier le jeton de réinitialisation de mot de passe
    axios
      .get(`/mot-de-passe-oublie/${token}`)
      .then((res) => {
        setEmail(res.data.email);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.msg);
      });
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Valider le formulaire
    if (password !== confPassword) {
      setErrorMessage("Les mots de passe ne correspondent pas");
      return;
    }

    // Envoyer la requête de réinitialisation du mot de passe
    axios
      .patch(`/mot-de-passe-oublie/${token}`, { email, password, confPassword })
      .then((res) => {
        console.log('niquel mdp update');
        navigate("/login");
      })
      .catch((err) => {
        console.log('pas niquel mdp non update');
        setErrorMessage(err.response.data.msg);
      });
  };

  return (


    <form className="container is-flex is-flex-direction-column " onSubmit={handleSubmit}>
    <div className="subtitle is-flex is-justify-content-center">Réinitialisation de votre mot de passe</div>
    <div className="field">
      <label className="label">Votre adresse email :</label>
      <div className="control">
        <input
          required
          type="email"
          className="input"
          placeholder="Email"
          value={email}
          disabled
          draggable
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
    </div>

    <div className="field">
      <label className="label">Nouveau mot de passe</label>
      <div className="control">
        <input
          type={showPassword ? "text" : "password"}
          className="input"
          placeholder="*****"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <span
          className="icon is-right"
          onClick={() => setShowPassword(!showPassword)}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "0.5rem",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
        </span>
      </div>
    </div>

    <div className="field">
      <label className="label">Confirmation du nouveau mot de passe</label>
      <div className="control">
        <input
          type={showPassword ? "text" : "password"}
          className="input"
          placeholder="*****"
          value={confPassword}
          onChange={(e) => setConfPassword(e.target.value)}
          required
        />
        <span
          className="icon is-right"
          onClick={() => setShowPassword(!showPassword)}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "0.5rem",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
        </span>
      </div>
    </div>
    
    <button type="submit" className="button">
      Changer de mot de passe
    </button>

    <p className="help has-text-danger">{errorMessage && <p>{errorMessage}</p>}
</p>
  </form>



      




  );
};

export default ResetPasswordMail;
