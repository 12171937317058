import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Layout from "./pages/Layout";
import Login from "./components/Login";
import Users from "./pages/admin/Users";
import Vehicules from "./pages/Vehicules";
import AddUser from "./pages/admin/AddUser";
import EditUser from "./pages/admin/EditUser";
import AddVehicule from "./pages/admin/AddVehicule";
import EditVehicule from "./pages/admin/EditVehicule";
import PannelControl from "./pages/admin/PannelControl";
import Register from "./pages/Register";
import QuiSommeNous from "./pages/QuiSommeNous";
import AdminVehiculeView from "./pages/admin/AdminVehiculeView";
import Verification from "./pages/Verification";
import ProfilPage from "./pages/ProfilPage";
import ResendCode from "./components/ResendCode";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import Error404Page from "./pages/Error404Page";
import ResetPasswordMail from "./pages/ResetPasswordMailPage";
import ReservationVehiculePage from "./pages/ReservationVehiculePage";
import ReservationDetailsPage from "./pages/ReservationDetailsPage";
import Payement from "./pages/Payement";
import StripeContainer from "./stripe/StripeContainer"
import RecapAndPayement from "./components/RecapAndPayement";
import RemerciementPage from "./pages/RemerciementPage";
import AllReservationPage from "./pages/admin/AllReservationPage";

function App() {
  return (
    <div>
    

        <BrowserRouter>
        
          <Routes>
            <Route path="/" element={<Dashboard />}></Route>

            <Route path="/login" element={<Login />}></Route>

            <Route path="/qui-sommes-nous" element={<QuiSommeNous />}></Route>

            <Route path="/register" element={<Register />}></Route>

            <Route path="/profil/:uuid" element={<ProfilPage />}></Route>

            <Route path="/profil/update/:uuid" element={<ProfilPage />}></Route>

            <Route
              path="/reset-password/:uuid"
              element={<ResetPasswordPage />}
            ></Route>

            <Route
              path="/mot-de-passe-oublie"
              element={<ForgotPasswordPage />}
            ></Route>

            <Route
              path="/mot-de-passe-oublie/:token"
              element={<ResetPasswordMail />}
            ></Route>

            <Route path="/send-code" element={<ResendCode />}></Route>

            <Route path="/verification" element={<Verification />}></Route>

            <Route path="/dashboard" element={<Dashboard />}></Route>

            <Route path="/users" element={<Users />}></Route>

            <Route path="/pannel" element={<PannelControl />}></Route>

            <Route path="/users/edit/:id" element={<EditUser />}></Route>

            <Route path="/users/add" element={<AddUser />}></Route>

            <Route path="/vehicules" element={<Vehicules />}></Route>

            <Route
              path="/AdminVehiculeView"
              element={<AdminVehiculeView />}
            ></Route>

            <Route path="/vehicules/add" element={<AddVehicule />}></Route>

            <Route
              path="/vehicules/edit/:id"
              element={<EditVehicule />}
            ></Route>

            <Route
              path="/vehicules/reservation/:uuid"
              element={<ReservationVehiculePage />}
            ></Route>

            <Route
              path="/vehicules/reservation/:uuid"
              element={<ReservationVehiculePage />}
            ></Route>

            <Route
              path="/reservation/information-conducteur/:uuid"
              element={<ReservationDetailsPage />}
            ></Route>

            <Route path="/reservation/payement/:uuid" element={<RecapAndPayement/>}></Route>

            <Route path="/remerciement" element={<RemerciementPage/>}></Route>


            <Route path="/all-reservations" element={<AllReservationPage/>}></Route>


            


            <Route path="*" element={<Error404Page />}></Route>
          </Routes>
        </BrowserRouter>

        
    </div>
  );
}

export default App;
