import React, { useEffect, useState, useRef } from "react";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import backgroundImage from "../assets/images/bg_home.jpg";
import "../assets/styles/homePage.css";
import Calendar from "../components/Calendar";
import ButtonNosVehicules from "../components/ButtonNosVehicules";
import ButtonQuiSommeNous from "../components/ButtonQuiSommeNous";
import Footer from "../components/Footer";
import VehiculeList from "../components/VehiculeList";
import BannerVerification from "../components/BannerVerification";
import VehicleCard from "../components/VehicleCard";
import axios from "axios";


const Dashboard = (vehicule) => {
  const availableVehiclesRef = useRef(null);
  const [availableVehicles, setAvailableVehicles] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [vehicleType, setVehicleType] = useState("");
  const [agency, setAgency] = useState("");

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await axios.post(
      `https://dev.ninoloc.fr/api/reservations/available-vehicles`,
      {
        startDate,
        startTime,
        endDate,
        endTime,
        agency,
        vehicleType,
      }
    );
    setAvailableVehicles(response.data.availableVehicles);

    availableVehiclesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // useEffect(() => {
  //   if (isError) {
  //     navigate("/");
  //   }
  // }, [isError, navigate]);

  return (
    <Layout>
      <BannerVerification></BannerVerification>

      <div
        class="is-flex is-flex-direction-column-reverse pt-6 pb-6 mb-6"
        id="containerBoxCalendar"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="columns is-gapless">
          <div class="box" id="calendar">
            <div className="colomn is-half">
              <h1 class="title">Louer un véhicule</h1>
              <Calendar
                handleSubmit={handleSubmit}
                startDate={startDate}
                endDate={endDate}
                startTime={startTime}
                endTime={endTime}
                vehicleType={vehicleType}
                agency={agency}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
                setVehicleType={setVehicleType}
                setAgency={setAgency}
              ></Calendar>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 mb-5" ref={availableVehiclesRef}>
        <div className="columns is-multiline">

          {availableVehicles.map((vehicule) => (
            <div key={vehicule.id} className="column is-3">
              <VehicleCard vehicule={vehicule} />
            </div>
          ))}
        
        </div>
      </div>

      <div className="container is-flex is-justify-content-center">
        <div className="title" id="phraseAccroche">
          Louer un véhicule en toute sécurité au meilleur prix !
        </div>
      </div>

      <div className="columns mt-6 is-flex is-justify-content-center is-flex-wrap-wrap">
        <div className="column mt-6 is-4">
          <ButtonNosVehicules></ButtonNosVehicules>
        </div>
        <div className="column mt-6 is-4">
          <ButtonQuiSommeNous></ButtonQuiSommeNous>
        </div>
      </div>
      <div className="container-fluid is-flex is-justify-content-center mt-6">
        <div className="title" id="phraseAccroche">
          Découvrez dès maintenant notre sélection des véhicules les plus
          populaires parmi nos clients :
        </div>
      </div>
      <div className=""></div>
      <Footer></Footer>
    </Layout>
  );
};

export default Dashboard;
