import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ImageQuiSommeNous from "../assets/images/ImageQuiSommeNous.jpg";
import '../assets/styles/StyleQuiSommeNous.css'

const ButtonQuiSommeNous = () => {


  const navigate = useNavigate()

  const QuiSommeNous = () => {
    navigate("/qui-sommes-nous");
  };

  return (
  <div className=""   onClick={QuiSommeNous} >
     <div className="container has-background-grey-lighter is-flex is-flex-direction-column is-clickable " id="containerQuiSommeNous">
      <div className="container">
        <div className="subtitle mt-5" id="fontButton">Qui somme nous ? </div>
      </div>
      <div className="container">
        <figure class="image" id="imageQuiSommeNous">
          <img src={ImageQuiSommeNous} alt="ImageQuiSommeNous" />
        </figure>
      </div>
    </div>
  </div>
   
  );
};

export default ButtonQuiSommeNous;
