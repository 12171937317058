import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoginUser, reset, LogOut } from "../features/authSlice";
import logoNinoloc from "../assets/images/LogoNinoloc.svg";
import Welcom from "./Welcom";
import "../assets/styles/Navbar.css";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );



  const logout = () => {
    dispatch(LogOut()); //fonction logout de redux
    dispatch(reset()); //écrase la session
    navigate("/dashboard"); //retour au / 
  };

  const login = () => {
    navigate("/login"); 
  };

  const register = () => {
    navigate("/register");
  };

  const profil = () => {
    navigate(`/profil/${user.uuid}`);
  };
  

  const pannel = () => {
    navigate("/pannel");
  };

  return (
    <div>
      <nav
        className="navbar has-shadow navbar-height"
        id="navbar"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <NavLink to="/dashboard" className="navbar-item" id="navbar_item">
            <img
              src={logoNinoloc}
              width="199"
              height="auto"
              alt="logo Ninoloc"
            />
          </NavLink>

          <a
            //manque le respondive
            href="!#"
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <div className="navbar-item">
              <NavLink
                className="has-text-black  mr-5 is-size-4"
                to="/dashboard"
              >
                Accueil
              </NavLink>
              <NavLink
                className="has-text-black mr-5  is-size-4"
                to="/vehicules"
              >
                Nos véhicules
              </NavLink>
              <NavLink className="has-text-black mr-5 is-size-4" to="/qui-sommes-nous">
                Qui sommes-nous ?
              </NavLink>
            </div>
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              {isSuccess && (
                <p className="has-text-black mr-3">
                  Bonjour, <strong>{user && user.firstname}</strong>
                </p>
              )}

              <div className="buttons">
                {/* Verification si user  est connecté */}
                {isSuccess && (
                  <button onClick={logout} className="button is-light">
                    Déconnexion
                  </button>
                )}

                {isSuccess && (
                  <button onClick={profil} className="button is-light">
                    Profil
                  </button>
                )}

                {!isSuccess && (
                  <button onClick={login} className="button is-light">
                    Se connecter
                  </button>
                )}
                {!isSuccess && (
                  <button onClick={register} className="button is-light">
                    Créer un compte
                  </button>
                )}


                  {user && (user.role === "admin" || user.role === "superAdminOnly") &&(
                  <button onClick={pannel} className="button is-light">
                    Panneau de control
                  </button>
                  )}
                
              

              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
