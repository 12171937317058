import React, { useEffect, useState } from "react";
import { confirm } from "react-confirm-box";
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import { faGasPump } from "@fortawesome/free-solid-svg-icons";
import { faSnowflake } from "@fortawesome/free-solid-svg-icons";
import { faPerson } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import Gear from "../assets/images/gear.png";
import FiltreVehicule from "./FiltreVehicule";
import { Tabs, Tab, Typography } from "@material-ui/core";
import { CSSTransition, TransitionGroup } from "react-transition-group";
// import image from '../../../backend/uploads/image-1677580712869demenager-frigo.jpeg'

import "../assets/styles/card.css";

const VehiculeList = () => {
  const [vehicule, setVehicule] = useState([]);
  const [filter, setFilter] = useState(null); // état de filtre initialisé à null
  const [activeTab, setActiveTab] = useState("all");

  useEffect(() => {
    getVehicule();
  }, []);

    const getVehicule = async () => {
      const response = await axios.get("https://dev.ninoloc.fr/api/vehicules");
      setVehicule(response.data);
    };

  const handleTabChange = (event, newValue) => {
    console.log("active tab:", newValue);
    setActiveTab(newValue);
  };

  const filterVehicules = () => {
    if (activeTab === "all") {
      return vehicule;
    } else {
      return vehicule.filter((v) => v.category === activeTab);
    }
  };

  const getTitle = () => {
    switch (activeTab) {
      case "utilitaire":
        return "Véhicules utilitaires";
      case "tourisme":
        return "Véhicules de tourisme";
      default:
        return "Tous les véhicules";
    }
  };


  return (
    <div className="container is-fluid mt-6">
      <Typography className="is-flex is-justify-content-center" variant="h3" component="h2" gutterBottom >
        {getTitle()}
      </Typography>
      <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth" className="mb-6">
        <Tab className="is-size-4" value="all" label="Tous nos véhicules" />
        <Tab  className="is-size-4" value="utilitaire" label="Véhicules Utilitaires" />
        <Tab className="is-size-4" value="tourisme" label="Véhicules de tourismes" />
      </Tabs>
      <div className="columns is-multiline is-centered">
        {filterVehicules().map((vehicule, index) => (
          <div key={vehicule.uuid} className="column is-3">
            
            <div className="card">
              <div className="card-image">
                <figure className="image is-4by3">
                  <img
                    src={`https://dev.ninoloc.fr/uploads/${vehicule.pictures}`}
                    alt={`image ${vehicule.name}`}
                  />
                </figure>
              </div>
              <div className="card-content">
                <h1 className="title">{vehicule.name}</h1>
                <h2 className="subtitle has-text-weight-bold">
                  à partir de {vehicule.price} € / jours
                </h2>
                <div className="is-flex is-justify-content-center mb-6">
                     <Link to={`/vehicules/reservation/${vehicule.uuid}`} ><button className="button">Réserver ce véhicule</button></Link>
                       
                        
                
                  
                </div>
                <div className="columns">
                  <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center ">
                    <FontAwesomeIcon icon={faPerson} className="icon_card" />
                    {vehicule.number_places}
                  </div>
                  <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                    <FontAwesomeIcon icon={faGasPump} className="icon_card" />
                    {vehicule.type_fuel}
                  </div>
                  <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                    <FontAwesomeIcon icon={faIdCard} className="icon_card" />
                    {vehicule.age_drive_license}
                  </div>
                  <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                    <img src={Gear} alt="" className="icon_card" />
                    {vehicule.gear_type}
                  </div>
                  <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                    <FontAwesomeIcon icon={faSnowflake} className="icon_card" />
                    {vehicule.air_conditioning}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VehiculeList;
