import React from 'react'
import Layout from "./Layout";
import Profil from '../components/Profil';
import BannerVerification from "../components/BannerVerification";


const ProfilPage = () => {
  return (
    <Layout>
      <BannerVerification></BannerVerification>
      <Profil></Profil>
    </Layout>
   
   
  )
}

export default ProfilPage