import React, { useState, useEffect, useMemo } from "react";
import cryptoRandomString from 'crypto-random-string';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";




const ResendCode = () => {

const navigate = useNavigate()

const [email, setEmail] = useState("")

const [code_verification, setVerification] = useState("");

const [msg, setMsg] = useState("")



useEffect(() => {
    const codeVerif = cryptoRandomString({length: 5, type: 'numeric'});
    setVerification(codeVerif)
    console.log("code verification front")
    console.log(codeVerif);
},[])   


const sendMailCode = async (e) => {
  e.preventDefault();
  try {
    await axios.post("https://dev.ninoloc.fr/api/send-code",{
   
      code_verification : code_verification,
      email : email
      


    });
    navigate("/verification"); //passage de l'EMAIL dans l'autre page
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
    }
  }
};








//   e.preventDefault();
//   try {
//       await axios.post("https://dev.ninoloc.fr/api/send-code", {
//         code_verification : code_verification,
//         email : email
        
//     });
//     navigate("/verification", {state: {email : email}}); //passage de l'EMAIL dans l'autre page
//   } catch (error) {
//     console.log(error.response);
//     if (error.response) {
//       setMsg(error.response.data.msg);
//     }
//   }

  const test = ()=>{
    navigate("/test");
  }


  return (
    <form onSubmit={sendMailCode}>
        <div className="field is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
          <label className="label">Merci de saisir votre addresse mail associer à votre compte Ninoloc</label>
          <div className="control">
            <input
              type="text"
              className="input"
              placeholder="Votre mail associer au compte Ninoloc"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="field is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
          <p className="has-text-centered mb-2 has-text-danger ">{msg}</p>
  
          <div className="control">
            <button type="submit" className="button is-success">
              Envoyer
            </button>
          </div>
        </div>
      </form>
  )
}

export default ResendCode