import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";

const ForgotPassword = () => {

  const [email, setEmail] = useState("")
  const[msg, setMsg] = useState("")
  const[msgClass, setMsgClass] = useState("")


const resetPasswordMails = async (e) => {
  e.preventDefault();
  try {
    await axios.post("https://dev.ninoloc.fr/api/forgot-password", { email });
    setMsg("Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse e-mail.");
    setMsgClass("has-text-success"); 
  } catch (error) {
    if (error.response) {
      setMsg(error.response.data.msg);
      setMsgClass("has-text-danger");
    }
  }
};


  return (
    <form  className="container is-flex is-flex-direction-column" onSubmit={resetPasswordMails}>
        <div className="field is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
          <label className="subtitle">Entrez votre adresse e-mail associée à votre compte Ninoloc pour réinitialiser votre mot de passe</label>
          <div className="control">
            <input
              size="40"
              type="text"
              className="input"
              placeholder="Adresse e-mail associée au compte Ninoloc"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="field is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
        <p className={`has-text-centered mb-2 ${msgClass}`}>{msg}</p> {/* La classe "has-text-danger" est ajoutée conditionnellement en utilisant l'opérateur logique && */}

  
          <div className="control">
            <button type="submit" className="button is-success">
              Valider
            </button>
          </div>
        </div>
      </form>
  )
}

export default ForgotPassword