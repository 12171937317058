import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import { Tabs, Tab, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const BannerVerification = () => {


  const [activeParentTab, setActiveParentTab] = useState(0);
  const [activeChildTab, setActiveChildTab] = useState(0);
  const [showInfo, setShowInfo] = useState(false);
  const [showReservations, setShowReservations] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, isLoading, message, phone_number } =
    useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);



  const handleParentTabChange = (event, newValue) => {
    setActiveParentTab(newValue);
  };

  const handleChildTabChange = (event, newValue) => {
    setActiveChildTab(newValue);
  };

//   useEffect(() => {
//     if (user && user.is_verified === true) {
//       console.log("utilisateur vérifié");
//     } else {
//       console.log("utilisateur non vérifié");
//     }
//   }, [user]);

  
  const handleVerifieCode = () => {
    navigate("/verification" )
  }
  return (
    <div className="is-flex container is-fluid  has-background-warning is-justify-content-center  ">
      {isSuccess && user && !user.is_verified ? (
        <div className="is-flex is-flex-direction-column is-align-items-center">Votre compte n'est pas vérifié <div className="is-clickable has-text-danger has-text-weight-bold " onClick={handleVerifieCode}>Cliquer ici</div></div>
    
        
      ) : null}
    </div>
  );
};

export default BannerVerification;
