import React from "react";

const QuiSommeNous = () => {
  return (
    <div className="container is-fluid">
      <div className="columns pt-6">
        <div className="column">
          <p className="title has-text-centered">
            Bienvenue chez Ninoloc
          </p>
          <p className="is-size-5 has-text-centered">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
            consectetur accusamus consequuntur, cum similique eum voluptatum
            eveniet magnam neque unde aliquam nulla mollitia vel nostrum facilis
            voluptatibus laudantium voluptates animi.
          </p>
        </div>
      </div>
      <div className="columns mt-6">
        <div className="column">
          <p className="title has-text-centered">Notre histoire</p>
          <p className="is-size-5 has-text-centered">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae
            amet impedit, qui veniam aperiam blanditiis optio culpa eius autem,
            odio veritatis explicabo facilis quas temporibus. Ipsam tempora
            saepe nihil delectus!
          </p>
        </div>
        <div className="column">
          <figure className="image is-16by9 is-640x360">
            <img src="https://bulma.io/images/placeholders/640x360.png" />
          </figure>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <figure className="image is-16by9 is-640x360">
            <img src="https://bulma.io/images/placeholders/640x360.png" />
          </figure>
        </div>
        <div className="column">
          <p className="title has-text-centered">
            Notre mission
          </p>
          <p className="is-size-5 has-text-centered">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
            adipisci necessitatibus maiores nobis quam id laboriosam neque in
            laborum, dolorum delectus reiciendis eligendi sunt corrupti totam
            voluptatum labore incidunt deleniti.
          </p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p className="title has-text-centered">
            Notre parc automobile
          </p>
          <p className="is-size-5 has-text-centered">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia
            corporis quod adipisci iste fuga animi esse voluptas dolor quisquam
            ea, a perferendis voluptatibus officiis placeat ad id aut nemo
            sapiente.
          </p>
        </div>

        <div className="column">
          <figure className="image is-16by9 is-640x360">
            <img src="https://bulma.io/images/placeholders/640x360.png" />
          </figure>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p className="title has-text-centered">Nous sommes là pour vous :</p>

        </div>
      </div>
    </div>
  );
};

export default QuiSommeNous;
