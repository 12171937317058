import React from 'react'
import ReservationVehicule from '../components/ReservationVehicule'
import Layout from './Layout'
import CalendarAndReservation from '../components/CalendarAndReservation'
import BannerVerification from "../components/BannerVerification";

const ReservationVehiculePage = () => {
  return (
    <Layout>
        <BannerVerification></BannerVerification>
        <CalendarAndReservation></CalendarAndReservation>
    </Layout>
  )
}

export default ReservationVehiculePage