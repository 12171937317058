import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import countries from "./countries.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "@geoapify/geocoder-autocomplete/styles/minimal.css";
import cryptoRandomString from "crypto-random-string";

const RegisterCompo = () => {
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [born_date_user, setBirthDateUser] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number_portable, setPortable] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zip_code, setZip] = useState("");
  const [role, setRole] = useState("");
  const [msg, setMsg] = useState("");
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [code_verification, setVerification] = useState("");
  const [is_verified, setIsVerified] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const codeVerif = cryptoRandomString({ length: 5, type: "numeric" });
    setVerification(codeVerif);
    console.log("code verification front");
    console.log(codeVerif);
  }, []);

  const saveUser = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://dev.ninoloc.fr/api/register", {
        name: name,
        firstname: firstname,
        born_date_user: born_date_user,
        email: email,
        phone_number_portable: phone_number_portable,
        password: password,
        confPassword: confPassword,
        country: country,
        city: city,
        zip_code: zip_code,
        role: "user",
        code_verification: code_verification,
        is_verified: is_verified,
      });
      navigate("/verification", { state: { email: email } }); //passage de l'EMAIL dans l'autre page
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  return (
    <section className="hero is-fullheight is-fullwidth">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-4">
              {step === 1 && (
                <form onSubmit={nextStep} className="box">
                  <h1 className="title is-2">Inscription</h1>

                  <div className="field">
                    <label className="label">Prénom*</label>
                    <div className="control">
                      <input
                        required
                        type="text"
                        className="input"
                        placeholder="Prénom"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Nom*</label>
                    <div className="control">
                      <input
                        required
                        type="text"
                        className="input"
                        placeholder="Nom"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Date de naissance*</label>
                    <div className="control">
                      <input
                        required
                        type="date"
                        className="input"
                        placeholder="Prénom"
                        value={born_date_user}
                        onChange={(e) => setBirthDateUser(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Email*</label>
                    <div className="control">
                      <input
                        required
                        type="email"
                        className="input"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label ">Téléphone portable</label>
                    <div className="control">
                      <input
                        type="tel"
                        className="input"
                        placeholder="ex : 07..."
                        value={phone_number_portable}
                        onChange={(e) => setPortable(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <p className="help is-danger mb-2">Champs obligatoires *</p>
                    <p className="has-text-centered mb-2  has-text-danger ">
                      {msg}
                    </p>

                    <div className="control">
                      <button
                        type="submit"
                        className="button is-success is-fullwidth"
                      >
                        Suivant
                      </button>
                    </div>
                  </div>
                  <div className="field">
                    <p className="has-text-centered">
                      Vous avez déjà un compte ?
                      <a href="/login"> Connectez-vous</a>
                    </p>
                  </div>
                </form>
              )}

              {step === 2 && (
                <form onSubmit={saveUser} className="box">
                  <h1 className="title is-2">Inscription</h1>
                  <div className="field">
                    <label className="label">Pays</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          <option value="">Selectionner un pays</option>
                          {countries.map((country) => (
                            <option key={country} value={country}>
                              {country}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Ville</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        placeholder="Ville"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Code postal</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        placeholder="Code postal"
                        value={zip_code}
                        onChange={(e) => setZip(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Password</label>
                    <div className="control">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="input"
                        placeholder="*****"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <span
                        className="icon is-right"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "0.5rem",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label"> confirme Password</label>
                    <div className="control">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="input"
                        placeholder="*****"
                        value={confPassword}
                        onChange={(e) => setConfPassword(e.target.value)}
                        required
                      />
                      <span
                        className="icon is-right"
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "0.5rem",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </span>
                    </div>
                  </div>
                 
                  <div className="field">
                    <p className="help is-danger mb-2">Champs obligatoires *</p>
                    <p className="has-text-centered mb-2 has-text-danger ">
                      {msg}
                    </p>

                    <div className="control">
                      <button
                        type="button"
                        className="button is-link"
                        onClick={prevStep}
                      >
                        Précédent
                      </button>
                      <button type="submit" className="button is-success">
                        S'inscrire
                      </button>
                    </div>
                  </div>
                  <div className="field">
                    <p className="has-text-centered">
                      Vous avez déjà un compte ?
                      <a href="/login"> Connectez-vous</a>
                    </p>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterCompo;
