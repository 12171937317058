import React, { useState } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import moment from "moment";
import DatePicker from "react-datepicker";
import "../assets/styles/ReservationForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import TimePicker from "react-time-picker";
import axios from "axios";
import VehicleCard from "./VehicleCard";

moment.locale("fr");

registerLocale("fr", fr);

setDefaultLocale("fr");

const ReservationForm = ({
  handleSubmit,
  startDate,
  endDate,
  startTime,
  endTime,
  vehicleType,
  agency,
  setStartDate,
  setEndDate,
  setStartTime,
  setEndTime,
  setVehicleType,
  setAgency,
}) => {
  // const [availableVehicles, setAvailableVehicles] = useState([]);

  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  // const [startTime, setStartTime] = useState(null);
  // const [endTime, setEndTime] = useState(null);
  // const [vehicleType, setVehicleType] = useState("");
  // const [agency, setAgency] = useState("");

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   const response = await axios.post(
  //     `https://dev.ninoloc.fr/api/reservations/available-vehicles`,
  //     {
  //       startDate,
  //       startTime,
  //       endDate,
  //       endTime,
  //       agency,
  //       vehicleType,
  //     }
  //   );
  //   setAvailableVehicles(response.data.availableVehicles);
  // };

  const today = moment().toDate();
  const numberOfDays = () => {
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const diff = end.diff(start, "days");
      return diff + 1;
    }
    return 0;
  };
  return (
    <div className="">
      <form onSubmit={handleSubmit} autocomplete="off">
        <div className="is-flex is-flex-direction-column mt-5">
          <label>Choisissez une agence:</label>
          <div className="select is-normal">
            <select
              required
              value={agency}
              onChange={(event) => setAgency(event.target.value)}
              className="form-control"
            >
              <option value="" disabled>
                Choisissez une agence
              </option>
              <option value="Loudéac">Loudéac</option>
            </select>
          </div>
        </div>
        <div className="is-flex is-flex-direction-column  mt-5">
          <label>Type de véhicule:</label>
          <div className="select is-normal">
            <select
              required
              value={vehicleType}
              onChange={(event) => setVehicleType(event.target.value)}
              className="form-control"
              id="selectVehicleType"
            >
              <option value="" disabled selected>
                Choisissez un type de véhicule
              </option>
              <option value="utilitaire">Utilitaire</option>
              <option value="tourisme">Tourisme</option>
            </select>
          </div>
        </div>
        <div className="is-flex is-justify-content-space-between is-align-content-center mt-5">
          <div className="form-group">
            <label>Date de début:</label>
            <div className="" id="">
              <DatePicker
                required
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                locale="fr"
                className="input"
                id="inputDatePicker"
                portalId="root-portal"
                minDate={today}
              />
            </div>
          </div>
          <div className="is-flex is-align-items-center mt-5">
            <div className="container">
              <FontAwesomeIcon
                icon={faArrowRight}
                className="icon_arrow"
              ></FontAwesomeIcon>
            </div>
          </div>
          <div className="form-group">
            <label>Date de fin:</label>
            <div className="control">
              <DatePicker
                required
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="dd/MM/yyyy"
                locale="fr"
                className="input"
                portalId="root-portal"
                id="inputDatePicker"
              />
            </div>
          </div>
        </div>

        <div className="is-flex is-justify-content-space-between is-align-content-center mt-5">
          <div className="form-group">
            <label>Heure de départ:</label>
            <div className="select" id="inputDatePicker">
              <select
                required
                className=""
                value={startTime}
                onChange={(event) => setStartTime(event.target.value)}
              >
                <option value="8">8:00</option>
                <option value="9">9:00</option>
                <option value="10">10:00</option>
                <option value="11">11:00</option>
                <option value="12">12:00</option>
                <option value="13">13:00</option>
                <option value="14">14:00</option>
                <option value="15">15:00</option>
                <option value="16">16:00</option>
                <option value="17">17:00</option>
                <option value="18">18:00</option>
              </select>
            </div>
          </div>
          {/* <div className="is-flex is-align-items-center mt-5">
          <div className="container">
            <FontAwesomeIcon
              icon={faArrowRight}
              className="icon_arrow"
            ></FontAwesomeIcon>
          </div>
        </div> */}
          <div className="form-group">
            <label>Heure d'arrivé</label>
            <div className="select" id="">
              <select
                required
                className=""
                value={endTime}
                onChange={(event) => setEndTime(event.target.value)}
              >
                <option value="8">8:00</option>
                <option value="9">9:00</option>
                <option value="10">10:00</option>
                <option value="11">11:00</option>
                <option value="12">12:00</option>
                <option value="13">13:00</option>
                <option value="14">14:00</option>
                <option value="15">15:00</option>
                <option value="16">16:00</option>
                <option value="17">17:00</option>
                <option value="18">18:00</option>
              </select>
            </div>
          </div>
        </div>

        <div className="is-flex  is-align-items-center is-justify-content-space-between mt-5">
          <div className="form-group">
            <p>Nombre de jours: {numberOfDays()}</p>
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Rechercher
            </button>
          </div>
        </div>

     
      </form>
    </div>
  );
};

export default ReservationForm;
