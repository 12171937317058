import React from 'react'
import ReservationDetails from '../components/ReservationDetails'
import Layout from './Layout'
import BannerVerification from "../components/BannerVerification";


const ReservationDetailsPage = () => {
  return (
    <Layout>
        <BannerVerification></BannerVerification>
        <ReservationDetails></ReservationDetails>
    </Layout>
  )
}

export default ReservationDetailsPage