import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import moment from "moment";
import DatePicker from "react-datepicker";
import "../assets/styles/ReservationForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import TimePicker from "react-time-picker";
import axios from "axios";
import VehicleCard from "./VehicleCard";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../features/authSlice";

moment.locale("fr");

registerLocale("fr", fr);

setDefaultLocale("fr");

const CalendarCheck = (props) => {

  const navigate = useNavigate();
  const { uuid } = useParams();
  // const [isVehicleAvailable, setIsVehicleAvailable] = useState("");
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  // const [agency, setAgency] = useState("");
  // const [startTime, setStartTime] = useState("");
  // const [endTime, setEndTime] = useState("");
  // const [nextAvailableDate, setNextAvailableDate] = useState(null);
  // const [showModal, setShowModal] = useState(false);
  // const [days, setDays] = useState(0);

  const {
    isVehicleAvailable, 
    setIsVehicleAvailable , 
    startDate , 
    setStartDate, 
    endDate, 
    setEndDate,
    agency,
    setAgency,
    startTime, 
    setStartTime,
    endTime,
    setEndTime,
    nextAvailableDate,
    setNextAvailableDate,
    showModal,
    setShowModal,
    days,
    setDays
  } = props




  const dispatch = useDispatch();
  const { user, isError, isSuccess, isLoading, message, phone_number } =
    useSelector((state) => state.auth);



  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  const login = () => {
    navigate("/login");
  };

  const register = () => {
    navigate("/register");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

  if (!isSuccess) {
      setShowModal(true);
      return;
  }

    try {
      const response = await axios.post(
        `https://dev.ninoloc.fr/api/reservations/checkvehicule/${uuid}`,
        {
          startDate: startDate,
          endDate: endDate,
          startTime: startTime,
          endTime: endTime,
          agency: agency,
        }
      );
      setIsVehicleAvailable(response.data.isVehicleAvailable);
      setNextAvailableDate(response.data.nextAvailableDate);
    } catch (error) {}
  };

  const today = moment().toDate();

  useEffect(() => {
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const diff = end.diff(start, "days");
      const numberOfDays = Math.abs(diff + 1);
      setDays(numberOfDays);
    } else {
      setDays(0);
    }
  }, [startDate, endDate]);
 

console.log ('ici jour')
console.log(days)

  return (
    <div className="">
      <form onSubmit={handleSubmit} autocomplete="off">
        <div className="is-flex is-flex-direction-column mt-5">
          <label>Choisissez une agence:</label>
          <div className="select is-normal">
            <select
              required
              value={agency}
              onChange={(event) => setAgency(event.target.value)}
              className="form-control"
            >
              <option value="" disabled>
                Choisissez une agence
              </option>
              <option value="Loudéac">Loudéac</option>
            </select>
          </div>
        </div>
        <div className="is-flex is-justify-content-space-between is-align-content-center mt-5">
          <div className="form-group">
            <label>Date de début:</label>
            <div className="" id="">
              <DatePicker
                required
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                locale="fr"
                className="input"
                id="inputDatePicker"
                portalId="root-portal"
                minDate={today}
              />
            </div>
          </div>
          <div className="is-flex is-align-items-center mt-5">
            <div className="container">
              <FontAwesomeIcon
                icon={faArrowRight}
                className="icon_arrow"
              ></FontAwesomeIcon>
            </div>
          </div>
          <div className="form-group">
            <label>Date de fin:</label>
            <div className="control">
              <DatePicker
                required
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="dd/MM/yyyy"
                locale="fr"
                className="input"
                portalId="root-portal"
                id="inputDatePicker"
              />
            </div>
          </div>
        </div>

        <div className="is-flex is-justify-content-space-between is-align-content-center mt-5">
          <div className="form-group">
            <label>Heure de départ:</label>
            <div className="select" id="inputDatePicker">
              <select
                required
                className=""
                value={startTime}
                onChange={(event) => setStartTime(event.target.value)}
              >
                <option value="8">8:00</option>
                <option value="9">9:00</option>
                <option value="10">10:00</option>
                <option value="11">11:00</option>
                <option value="12">12:00</option>
                <option value="13">13:00</option>
                <option value="14">14:00</option>
                <option value="15">15:00</option>
                <option value="16">16:00</option>
                <option value="17">17:00</option>
                <option value="18">18:00</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <label>Heure d'arrivé</label>
            <div className="select" id="">
              <select
                required
                className=""
                value={endTime}
                onChange={(event) => setEndTime(event.target.value)}
              >
                <option value="8">8:00</option>
                <option value="9">9:00</option>
                <option value="10">10:00</option>
                <option value="11">11:00</option>
                <option value="12">12:00</option>
                <option value="13">13:00</option>
                <option value="14">14:00</option>
                <option value="15">15:00</option>
                <option value="16">16:00</option>
                <option value="17">17:00</option>
                <option value="18">18:00</option>
              </select>
            </div>
          </div>
        </div>

        <div className="is-flex  is-align-items-center is-justify-content-space-between mt-5">
          <div className="form-group">
            <p>Nombre de jours: {days}</p>
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Reserver
            </button>
          </div>
        </div>

        {showModal && (
          <div className="modal is-active">
            <div
              className="modal-background"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Information</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => setShowModal(false)}
                ></button>
              </header>
              <section className="modal-card-body">
                <p>Veuillez vous connecter avant de reserver.</p>
              </section>

              <section className="modal-card-body">
                <button className="button" onClick={login}>
                  Se connecter
                </button>
                <button className="button" onClick={register}>
                  S'inscrire
                </button>
              </section>

              <footer className="modal-card-foot">
                <button className="button" onClick={() => setShowModal(false)}>
                  OK
                </button>
              </footer>
            </div>
          </div>
        )}
      </form>
      {isVehicleAvailable === true &&
        navigate(`/reservation/information-conducteur/${uuid}` )}
      {isVehicleAvailable === false && (
        <p>Le véhicule n'est pas disponible avant le {nextAvailableDate}.</p>
      )}
    </div>
  );
};

export default CalendarCheck;
