import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

function NinolocReviews() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    // Récupérez les avis Google pour Ninoloc en utilisant l'API Google Places
    fetch('https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJP9vJELn7sRIRGQrcbwHrlr0&fields=name,rating,reviews&key=VOTRE_CLE_D_API')
      .then(response => response.json())
      .then(data => {
        // Stockez les avis récupérés dans la variable d'état 'reviews'
        setReviews(data.result.reviews);
      })
      .catch(error => console.error(error));
  }, []);

  return (
    <div>
      <h2>Avis des clients</h2>
      {reviews.length > 0 ? (
        <ul>
          {reviews.map(review => (
            <li key={review.id}>
              <div>
                <p><FontAwesomeIcon icon={faStar} /> {review.rating}</p>
                <p>{review.text}</p>
                <p>par {review.author_name}</p>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>Pas encore d'avis pour Ninoloc.</p>
      )}
    </div>
  );
}

export default NinolocReviews;
