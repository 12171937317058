import React, { useState, useEffect } from "react";
import { confirm } from "react-confirm-box";
import { Link } from "react-router-dom";
import axios from "axios";

const UserList = () => {
  const [user, setUser] = useState([]);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const response = await axios.get("https://dev.ninoloc.fr/api/users");
    setUser(response.data);
  };

  const deleteUser = async (userId) => {
    const result = await confirm(
      `vous êtes sur de vouloir supprimer l'utilisateur`
    );
    if (result) {
      console.log("You click yes!");
      await axios.delete(`https://dev.ninoloc.fr/api/users/${userId}`);
      getUser();
      return;
    }
    console.log("You click No!");
  };
  return (
    <div>
      <h1 className="title">Users</h1>
      <h2 className="subtitle">list of Users</h2>
      <Link to="/users/add" className="button is-primary mb-2">
        Ajouter un utilisateur
      </Link>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>no</th>
            <th>Id user</th>
            <th>Prenom</th>
            <th>nom</th>
            <th>email</th>
            <th>Date de naissance</th>
            <th>Téléphone Portable</th>
            <th>Country</th>
            <th>city</th>
            <th>Code postal</th>
            <th>role</th>


          </tr>
        </thead>
        <tbody>
          {user.map((user, index) => (
            <tr key={user.uuid}>
              <td>{index + 1}</td>
              <td>{user.uuid}</td>
              <td>{user.firstname}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.born_date_user}</td>
              <td>{user.phone_number_portable}</td>
              <td>{user.country}</td>
              <td>{user.city}</td>
              <td>{user.zip_code}</td>
              <td>{user.role}</td>
              <td>
                <Link
                  to={`/users/edit/${user.uuid}`}
                  className="button is-small is-info"
                >
                  Modifier
                </Link>
                <button
                  onClick={() => deleteUser(user.uuid)}
                  className="button is-small is-danger"
                >
                  Supprimer
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserList;
