import React from 'react'
import Layout from './Layout';
import ResetPasword from '../components/ResetPasword';

const ResetPasswordPage = () => {
  return (
    <Layout>
        <ResetPasword></ResetPasword>
    </Layout>
  )
}

export default ResetPasswordPage