import React, { useState , useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { confirm } from "react-confirm-box";


const FormEditVehicule = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");
  const [type_fuel, setFuel] = useState("");
  const [number_places, setNumberPlace] = useState("");
  const [age_drive_license, setLicense] = useState("");
  const [animals, setAnimals] = useState("");
  const [pictures, setPictures] = useState("");
  const [gear_type, setGear] = useState("");
  const [number_doors, setDoors] = useState("");
  const [air_conditioning, setAir] = useState("");
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [volume, setVolume] = useState("");
  const [category, setCategory] = useState("");
  const [msg, setMsg] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    const getVehiculeById = async () => {
      try {
        const response = await axios.get(
          `https://dev.ninoloc.fr/api/vehicules/${id}`
        );
        setName(response.data.name);
        setPrice(response.data.price);
        setDescription1(response.data.description1);
        setDescription2(response.data.description2);
        setFuel(response.data.type_fuel);
        setNumberPlace(response.data.number_places);
        setLicense(response.data.age_drive_license);
        setAnimals(response.data.animals);
        setPictures(response.data.pictures);
        setGear(response.data.gear_type);
        setDoors(response.data.number_doors);
        setAir(response.data.air_conditioning);
        setHeight(response.data.height);
        setWidth(response.data.width);
        setVolume(response.data.volume);
        setCategory(response.data.category);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getVehiculeById();
  }, [id]);


  const formData = new FormData();
  formData.append("pictures", pictures);
  formData.append("name", name);
  formData.append("price", price);
  formData.append("description1", description1);
  formData.append("description2", description2);
  formData.append("number_places", number_places);
  formData.append("type_fuel", type_fuel);
  formData.append("age_drive_license", age_drive_license);
  formData.append("animals", animals);
  formData.append("number_doors", number_doors);
  formData.append("air_conditioning", air_conditioning);
  formData.append("gear_type", gear_type);
  formData.append("height", height);
  formData.append("width", width);
  formData.append("volume", volume);
  formData.append("category", category);

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }

  const UpdateVehicule = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`https://dev.ninoloc.fr/api/vehicules/${id}`, formData, config ) ;
      navigate("/vehicules");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  const setimagefile = (e) => {
    setPictures(e.target.files[0])
  }

  const goBack = () => {
    navigate(-1);
}
  return (
    <div className="mt-6">
      <div className="columns">
        <div className="column">
          <button className="button " onClick={goBack}>
            Retour
          </button>
        </div>
      </div>
      <h1 className="title">Vehicule</h1>
      <h2 className="subtitle">Modifier un vehicule</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={UpdateVehicule}>
              <p className="has-text-centered">{msg}</p>

              <div className="field">
                <label className="label">Model</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Model"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Prix</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Prix / semaine"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Description 1</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Description"
                    value={description1}
                    onChange={(e) => setDescription1(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Description 2</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Description"
                    value={description2}
                    onChange={(e) => setDescription2(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Nombre de place</label>
                <div className="control">
                  <div className="select">
                    <select
                      value={number_places}
                      onChange={(e) => setNumberPlace(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Selectioner le nombre de places
                      </option>

                      <option value="2">2</option>
                      <option value="4">4</option>
                      <option value="6">6</option>
                      <option value="8">8</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Type de carburant</label>
                <div className="control">
                  <div className="select">
                    <select
                      value={type_fuel}
                      onChange={(e) => setFuel(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Selectioner le carburant
                      </option>
                      <option value="Essence">Essence </option>
                      <option value="Diesel">Diesel</option>
                      <option value="Gaz naturel">Gaz naturel</option>
                      <option value="Bioéthanol">Bioéthanol</option>
                      <option value="Hydrogène">Hydrogène</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">permis</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Age pour le permis"
                    value={age_drive_license}
                    onChange={(e) => setLicense(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Animaux</label>
                <div className="control">
                  {/* <input type="text"  value={animals}  onChange={(e) => setAnimals(e.target.value)}/> */}
                  <label className="radio">
                    <input
                      type="radio"
                      name="question"
                      value="oui"
                      checked={animals === "oui"}
                      onChange={(e) => setAnimals(e.target.value)}
                    />
                    Oui
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="question"
                      value="non"
                      checked={animals === "non"}
                      onChange={(e) => setAnimals(e.target.value)}
                    />
                    Non
                  </label>
                </div>
              </div>

              <div className="field">
                <label className="label">Photo</label>
                <div className="control">
                  <input
                    required
                    name="pictures"
                    type="file"
                    className="input"
                    placeholder="photo véhicule"
                    onChange={setimagefile}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Nombre de portes</label>
                <div className="control">
                  <div className="select">
                    <select
                      value={number_doors}
                      onChange={(e) => setDoors(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Selectioner le nombre de portes
                      </option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="6">6</option>
                      <option value="8">8</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Climatisation</label>
                <div className="control">
                  <label className="radio">
                    <input
                      type="radio"
                      name="air_conditioning"
                      value="oui"
                      checked={air_conditioning === "oui"}
                      onChange={(e) => setAir(e.target.value)}
                    />
                    Oui
                  </label>
                  <label className="radio">
                    <input
                      type="radio"
                      name="air_conditioning"
                      value="non"
                      checked={air_conditioning === "non"}
                      onChange={(e) => setAir(e.target.value)}
                    />
                    Non
                  </label>
                </div>
              </div>

              <div className="field">
                <label className="label">Boite de vitesse</label>
                <div className="control">
                  <div className="select">
                    <select
                      value={gear_type}
                      onChange={(e) => setGear(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Selectioner le type de boite de vittesse
                      </option>
                      <option value="Boîte manuelle ">Boîte manuelle </option>
                      <option value="Boîte automatique">
                        Boîte automatique
                      </option>
                      <option value="Boîte à double embrayage (DSG)">
                        Boîte à double embrayage (DSG)
                      </option>
                      <option value="Boîte de vitesses à variation continue (CVT)">
                        Boîte de vitesses à variation continue (CVT)
                      </option>
                      <option value="Boîte de vitesses électrique (EVT)">
                        Boîte de vitesses électrique (EVT)
                      </option>
                      <option value="Boîte de vitesses hybride">
                        Boîte de vitesses hybride
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Hauteur en m</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Description"
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Largeur en m</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Description"
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Volume en m3</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Description"
                    value={volume}
                    onChange={(e) => setVolume(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Catégorie</label>
                <div className="control">
                  <div className="select">
                    <select
                      required
                      name="category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Selectioner une catégorie
                      </option>
                      <option value="Essence">tourisme </option>
                      <option value="Diesel">utilitaire</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button
                    type="submit"
                    className="button is-success is-fullwidth"
                  >
                    Modifier
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditVehicule;
