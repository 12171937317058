import React, { useEffect, useState } from "react";
import { confirm } from "react-confirm-box";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const VehiculeList = () => {
  const [vehicule, setVehicule] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getVehicule();
  }, []);

  const getVehicule = async () => {
    const response = await axios.get("https://dev.ninoloc.fr/api/vehicules");
    setVehicule(response.data);
  };

  const deleteVehicule = async (vehiculeId) => {
    const result = await confirm(
      `vous êtes sur de vouloir supprimer le véhicule`
    );
    if (result) {
      console.log("You click yes!");
      await axios.delete(`https://dev.ninoloc.fr/api/vehicules/${vehiculeId}`);
      getVehicule();
      return;
    }
    console.log("You click No!");
  };
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="mt-6">
      <div className="columns">
        <div className="column">
          <button className="button " onClick={goBack}>
            Retour
          </button>
        </div>
      </div>
      <table className="table is-striped is-fullwidth mt-2">
        <thead>
          <tr>
            <th>n°</th>
            <th>model du véhicule</th>
            <th>Prix à la semaine</th>
            <th>Description</th>
            <th>Nombre de places</th>
            <th>Carburant</th>
            <th>Age permis de conduire</th>
            <th>Animaux</th>
            <th>photos</th>
            <th>Boite de vitesse</th>
            <th>Nombre de portes</th>
            <th>Climatisation</th>
            <th>Hauteur</th>
            <th>Largeur</th>
            <th>Volume</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {vehicule.map((vehicule, index) => (
            <tr key={vehicule.uuid}>
              <td>{index + 1}</td>
              <td>{vehicule.name}</td>
              <td>{vehicule.price}</td>
              <td>{vehicule.description}</td>
              <td>{vehicule.number_places}</td>
              <td>{vehicule.type_fuel}</td>
              <td>{vehicule.age_drive_license}</td>
              <td>{vehicule.animals}</td>
              <td>{vehicule.pictures}</td>
              <td>{vehicule.gear_type}</td>
              <td>{vehicule.number_doors}</td>
              <td>{vehicule.air_conditioning}</td>
              <td>{vehicule.height}</td>
              <td>{vehicule.width}</td>
              <td>{vehicule.volume}</td>
              <td className=" is-flex is-flex-direction-column">
                <Link
                  to={`/vehicules/edit/${vehicule.uuid}`}
                  className="button is-small is-info"
                >
                  Modifier
                </Link>
                <button
                  onClick={() => deleteVehicule(vehicule.uuid)}
                  className="button is-small is-danger"
                >
                  Supprimer
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VehiculeList;
