import React from "react";
import { useLocation } from "react-router-dom";
import CheckoutForm from "../stripe/CheckoutForm";
import StripeContainer from "../stripe/StripeContainer";
import Layout from "../pages/Layout";

const RecapAndPayement = () => {
  const { state } = useLocation();
  const reservationData = state.reservationData;
  console.log(state.reservationData);
  const vehicleData = state.vehicleData;
  return (

    <Layout  >
    <div className="container ">
      <div className="box mt-6">
        <div className="title">Récapitulatif</div>
        <div className="columns">
          <div className="column">
            <ul>
              <li>Nom: {reservationData.name}</li>
              <li>Prénom: {reservationData.firstname}</li>
              <li>Email: {reservationData.email}</li>
              <li>Date de naissance: {reservationData.born_date_user}</li>
              <li>Téléphone: {reservationData.phone_number_portable}</li>
              <li>Pays: {reservationData.country}</li>
              <li>Ville: {reservationData.city}</li>
              <li>Code postal: {reservationData.zip_code}</li>
            </ul>
          </div>
          <div className="column">
            <ul>
              <li>Modèle: {vehicleData.name}</li>
              <li>Agence: {vehicleData.agency}</li>
              <li>
                Date de départ:{" "}
                {vehicleData.startDate.toLocaleDateString("fr-FR")}
              </li>
              <li>
                Date de retour:{" "}
                {vehicleData.endDate.toLocaleDateString("fr-FR")}
              </li>
              <li>Heure de départ: {vehicleData.startTime} heures</li>
              <li>Heure de retour: {vehicleData.endTime} heures</li>
              <li>Total: {vehicleData.result}</li>
              <li>Kilomètres supplémentaires: {vehicleData.extraKms}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="box">
        <StripeContainer 
            totalAmount={vehicleData.result*100}
            email = {reservationData.email}
            name = {reservationData.name}
            vehiculeId = {vehicleData.id}
            startTime = {vehicleData.startTime}
            endTime = {vehicleData.endTime}
            startDate = {vehicleData.startDate}
            endDate = {vehicleData.endDate}
            vehicule_name = {vehicleData.name}
        ></StripeContainer>
      </div>
    </div>
    </Layout>
  );
};

export default RecapAndPayement;
