import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "@geoapify/geocoder-autocomplete/styles/minimal.css";

const ResetPasword = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [msg, setMsg] = useState("");
  const[msgClass, setMsgClass] = useState("")


  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  const navigate = useNavigate();

  const resetPassword = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`https://dev.ninoloc.fr/api/reset-password/${user.uuid}`, {
        email: email,
        oldPassword: oldPassword,
        password: password,
        confPassword: confPassword,
      });
      setMsg("Votre mot de passe a été changé avec succès. Vous allez être redirigé vers la page de connexion dans quelques secondes.");
      setMsgClass("has-text-success");
      
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
        setMsgClass("has-text-danger");

      }
    }
  };

  return (
    <form className="container" onSubmit={resetPassword}>
      <div className="field">
        <label className="label">Email*</label>
        <div className="control">
          <input
            required
            type="email"
            className="input"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Votre ancien mot de passe </label>
        <div className="control">
          <input
            type={showPassword ? "text" : "password"}
            className="input"
            placeholder="*****"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
          <span
            className="icon is-right"
            onClick={() => setShowPassword(!showPassword)}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "0.5rem",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </span>
        </div>
      </div>

     

      <div className="field">
        <label className="label">Nouveau mot de passe</label>
        <div className="control">
          <input
            type={showPassword ? "text" : "password"}
            className="input"
            placeholder="*****"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <span
            className="icon is-right"
            onClick={() => setShowPassword(!showPassword)}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "0.5rem",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </span>
        </div>
      </div>

      <div className="field">
        <label className="label">confirmation du nouveau mot de passe</label>
        <div className="control">
          <input
            type={showPassword ? "text" : "password"}
            className="input"
            placeholder="*****"
            value={confPassword}
            onChange={(e) => setConfPassword(e.target.value)}
            required
          />
          <span
            className="icon is-right"
            onClick={() => setShowPassword(!showPassword)}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "0.5rem",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </span>
        </div>
      </div>
      
      <button type="submit" className="button">
        Changer de mot de passe
      </button>
      <p className={`has-text-centered mb-2 ${msgClass}`}>{msg}</p> {/* La classe "has-text-danger" est ajoutée conditionnellement en utilisant l'opérateur logique && */}
      <NavLink to='/mot-de-passe-oublie'>mot de passe oublié ?</NavLink> 
      
    </form>
  );
};

export default ResetPasword;
