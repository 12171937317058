import React from 'react'
import Error404 from '../components/Error404'
import Layout from './Layout'


const Error404Page = () => {
  return (
    <Layout>
        <Error404></Error404>
    </Layout>
  )
}

export default Error404Page