import React, {useEffect} from 'react'
import Layout from './Layout'
import RegisterCompo from '../components/RegisterCompo'



const Register = () => {
   
  return (
    <Layout>
        <RegisterCompo></RegisterCompo>
    </Layout>
  )
}

export default Register