import React, {useEffect, useState} from "react";
import axios from "axios";

const ShowReservation = () => {
    const [ReservationUser, setReservationUser] = useState([])
    


    const GetReservation = async () => {
        try {
            const response = await axios.get('https://dev.ninoloc.fr/api/reservation/user')
            console.log('front ' , response.data)
            setReservationUser(response.data);
        } catch (error) {
            console.log("ERREOR")
            console.log('impossible de récupérer les réservations', error);
        }
    }

  
    useEffect(() => {
        GetReservation();
    }, []);

    console.log('front getreservation', ReservationUser)


    return (
        <div className="container">
            <div className="title">Vos réservations ({ReservationUser.length}) </div>
            <div className="container">
                {ReservationUser.map((reservation, index) => (
                    <div className="box" key={reservation.id}>
                        <p>Véhicule réservé : {reservation.vehicule_name}</p>
                        <p>ID de réservation : {reservation.id}</p>
                        <p>Date de départ : {reservation.startDate}</p>
                        <p>Date d'arriver : {reservation.startEnd}</p>
                        <p>Total : {reservation.totalAmount} €</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ShowReservation



