import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../features/authSlice";
import { useNavigate, useParams, useLocation, NavLink } from "react-router-dom";
import axios from "axios";


const VerificationCode = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [code_verification, setCodeverification] = useState("");
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState("");

    const dispatch = useDispatch();
    const { user, isError, isSuccess, isLoading, message, phone_number } =
      useSelector((state) => state.auth);
  
  
    useEffect(() => {
        if (location.state){
            setEmail(location.state.email);
        }else{
          setEmail(user.email);
        }
    }, [location.state])

  
    const verifyCode = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post("https://dev.ninoloc.fr/api/verify-code", {
            code_verification : code_verification,
            email : email
        });
        
        console.log(response.data);
        navigate("/login"); 
        
      } catch (error) {
        console.log(error.response);
        if (error.response) {
          setMsg(error.response.data.msg);
        }
        // handle verification error here
      }
    };
  
    return (
      <form onSubmit={verifyCode} className="container is-flex is-flex-direction-column is-align-items-center is-align-content-center">
        <div className="field is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
          <label className="mb-5">Un e-mail vient de vous être envoyé. Merci de consulter votre boîte de réception.</label>
          <div className="control">
            <input
              type="number"
              className="input"
              placeholder="Entrez le code de vérification"
              value={code_verification}
              onChange={(e) => setCodeverification(e.target.value)}
            />
          </div>
        </div>
        <div className="field is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
          <p className="has-text-centered mb-2 has-text-danger ">{msg}</p>  
          <div className="control is-flex is-align-items-center is-justify-content-center ">
            <button type="submit" className="button is-success mr-4">
              Vérifier
            </button>
            <NavLink to="/send-code">Pas de code ?</NavLink>

          </div>
        </div>
      </form>
    );
  };

export default VerificationCode;
