import React, {useEffect} from 'react'
import AllReservation from '../../components/AllReservation'
import Layout from '../Layout'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";

const Vehicules = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, isError, isSuccess, isLoading, message } = useSelector(
        (state) => state.auth
      );
  
    useEffect(() => {
      dispatch(getMe());
    }, [dispatch]);
  
    useEffect(() => {
      if (isError) {
        navigate("/");
      }
    }, [isError, navigate]);

    useEffect(() => {
        if(user && user.role === "user"){
            navigate("/");
        }
    })
  return (
    <Layout>
        <AllReservation></AllReservation>
    </Layout>
  )
}

export default Vehicules