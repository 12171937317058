import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FromAddVehicule = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");
  const [type_fuel, setFuel] = useState("");
  const [number_places, setNumberPlace] = useState("");
  const [age_drive_license, setLicense] = useState("");
  const [animals, setAnimals] = useState("");
  const [pictures, setPictures] = useState("");
  const [gear_type, setGear] = useState("");
  const [number_doors, setDoors] = useState("");
  const [air_conditioning, setAir] = useState("");
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [volume, setVolume] = useState("");
  const [category, setCategory] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  

  

  const saveVehicule = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("pictures", pictures);
    formData.append("name", name);
    formData.append("price", price);
    formData.append("description1", description1);
    formData.append("description2", description2);
    formData.append("number_places", number_places);
    formData.append("type_fuel", type_fuel);
    formData.append("age_drive_license", age_drive_license);
    formData.append("animals", animals);
    formData.append("number_doors", number_doors);
    formData.append("air_conditioning", air_conditioning);
    formData.append("gear_type", gear_type);
    formData.append("height", height);
    formData.append("width", width);
    formData.append("volume", volume);
    formData.append("category", category);
    // console.log(formData);
    // console.log(pictures)

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    
    try {
      await axios.post("https://dev.ninoloc.fr/api/vehicules", formData, config);
      navigate("/vehicules");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };



  const setimagefile = (e) => {
    setPictures(e.target.files[0])
  }




  const goBack = () => {
    navigate(-1);
}
  return (
    <div className="m-6">
       <div className="columns">
          <div className="column">
          <button className="button "onClick={goBack}>Retour</button>
          </div>
      </div>
      <h1 className="title">Vehicule</h1>
      <h2 className="subtitle">Ajouter un nouveaux véhicule</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={saveVehicule}>
              <div className="field">
                <label className="label">Nom du modèle :</label>
                <div className="control">
                  <input
                    required
                    type="text"
                    className="input"
                    placeholder="Model"
                    name = "name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Prix de la location par jours :</label>
                <div className="control">
                  <input
                    required
                    type="text"
                    className="input"
                    placeholder="Prix / jours"
                    name = "price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Déscription du véhicule (1ere description) </label>
                <div className="control">
                  <textarea
                    required
                    className="textarea is-medium"
                    placeholder="Description"
                    name = "description1"
                    value={description1}
                    onChange={(e) => setDescription1(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className="field">
                <label className="label">Déscription du véhicule : (2eme description)</label>
                <div className="control">
                  <textarea
                    required
                    className="textarea is-medium"
                    placeholder="Description"
                    name = "description2"
                    value={description2}
                    onChange={(e) => setDescription2(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className="field">
                <label className="label">Nombre de place disponnible : </label>
                <div className="control">
                  <div className="select">
                    <select
                      required
                      name = "number_places"
                      value={number_places}
                      onChange={(e) => setNumberPlace(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Selectioner le nombre de places
                      </option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="6">6</option>
                      <option value="8">8</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Type de carburant pour le véhicule :</label>
                <div className="control">
                  <div className="select">
                    <select
                      required
                      name = "type_fuel"
                      value={type_fuel}
                      onChange={(e) => setFuel(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Selectioner un carburant
                      </option>
                      <option value="Essence">Essence </option>
                      <option value="Diesel">Diesel</option>
                      <option value="Gaz naturel">Gaz naturel</option>
                      <option value="Bioéthanol">Bioéthanol</option>
                      <option value="Hydrogène">Hydrogène</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Age minimum pour louer ce véhicule</label>
                <div className="control">
                  <input
                    required
                    name="age_drive_license"
                    type="text"
                    className="input"
                    placeholder="Age pour le permis"
                    value={age_drive_license}
                    onChange={(e) => setLicense(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Les animaux sont t'il autorisé ? </label>
                <div className="control">
                  <label className="radio">
                    <input
                      required
                      type="radio"
                      name="animals"
                      value="oui"
                      checked={animals === "oui"}
                      onChange={(e) => setAnimals(e.target.value)}
                    />
                    Oui
                  </label>
                  <label className="radio">
                    <input
                      required
                      type="radio"
                      name="animals"
                      value="non"
                      checked={animals === "non"}
                      onChange={(e) => setAnimals(e.target.value)}
                    />
                    Non
                  </label>
                </div>
              </div>

              <div className="field">
                <label className="label">Photo pour la presentation du véhicule (photo visible sur la page nos véhicules)</label>
                <div className="control">
                  <input
                    required
                    name="pictures"
                    type="file"
                    className="input"
                    placeholder="photo véhicule"
                    onChange={setimagefile}
                    multiple 
                  />

                </div>
              </div>


             

              <div className="field">
                <label className="label">Nombre de portes</label>
                <div className="control">
                  <div className="select">
                    <select
                      required
                      name="number_doors"
                      value={number_doors}
                      onChange={(e) => setDoors(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Selectioner le nombre de portes
                      </option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="6">6</option>
                      <option value="8">8</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Le véhicule est-il equipé de la climatisation</label>
                <div className="control">
                  <label className="radio">
                    <input
                      required
                  
                      type="radio"
                      name="air_conditioning"
                      value="oui"
                      checked={air_conditioning === "oui"}
                      onChange={(e) => setAir(e.target.value)}
                    />
                    Oui
                  </label>
                  <label className="radio">
                    <input
                      required
                      type="radio"
                      name="air_conditioning"
                      value="non"
                      checked={air_conditioning === "non"}
                      onChange={(e) => setAir(e.target.value)}
                    />
                    Non
                  </label>
                </div>
              </div>

              <div className="field">
                <label className="label">Boite de vitesse</label>
                <div className="control">
                  <div className="select">
                    <select
                      required
                      name="gear_type"
                      value={gear_type}
                      onChange={(e) => setGear(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Selectioner le type de la boite de vittesse
                      </option>
                      <option value="M">Boîte manuelle </option>
                      <option value="A">
                        Boîte automatique
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Hauteur en mètre</label>
                <div className="control">
                  <input
                    required
                    name="height"
                    type="text"
                    className="input"
                    placeholder="Hauteur en m"
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Largeur en mètre</label>
                <div className="control">
                  <input
                    required
                    name="width"
                    type="text"
                    className="input"
                    placeholder="Description"
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Volume en mètre</label>
                <div className="control">
                  <input
                    required
                    name="volume"
                    type="text"
                    className="input"
                    placeholder="Description"
                    value={volume}
                    onChange={(e) => setVolume(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Catégorie</label>
                <div className="control">
                  <div className="select">
                    <select
                      required
                      name="category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Selectioner une catégorie
                      </option>
                      <option value="tourisme">tourisme</option>
                      <option value="utilitaire">utilitaire</option>
                    </select>
                  </div>
                </div>
              </div>

              <p className="has-text-centered">{msg}</p>

              <div className="field">
                <div className="control">
                  <button
                    type="submit"
                    className="button is-success is-fullwidth"
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FromAddVehicule;
