import Pannel from '../../components/Pannel'
import Layout from '../Layout'
import React, {useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";


const PannelControl = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isError, user } = useSelector((state) => state.auth);


        useEffect(() => {
            dispatch(getMe());
        }, [dispatch]);
    
      useEffect(() => {
        if (isError) {
          navigate("/");
        }
      }, [isError, navigate]);
  
      useEffect(() => {
          if(user && user.role === "user"){
              navigate("/");
          }
      })

  return (
    <Layout>
        <Pannel></Pannel>
    </Layout>
  )
}

export default PannelControl