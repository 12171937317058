import React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Pannel = () => {
  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  const navigate = useNavigate();

  const ConsultVehicule = () => {
    navigate("/AdminVehiculeView");
  };
  const AddVehicule = () => {
    navigate("/vehicules/add");
  };

  const ConsultUser = () => {
    navigate("/users");
  };
  const AddUser = () => {
    navigate("/users/add");
  };

  const ConsultReservation = () => {
    navigate("/all-reservations");
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="tile is-ancestor mt-6 is-flex-direction-column">
      <div className="tile is-parent is-flex-direction-column">
        <div className="columns">
          <div className="column">
            <button className="button " onClick={goBack}>
              Retour
            </button>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div class="box">
              <p className="title has-text-centered">Véhicules</p>
              <div className="columns is-flex is-justify-content-center is-flex-direction-column ">
                <button class="button mt-2" onClick={ConsultVehicule}>
                  Consulter la liste des véhicules
                </button>
                <button
                  class="button mt-2 has-background-success"
                  onClick={AddVehicule}
                >
                  Ajouter un véhicule
                </button>
              </div>
            </div>
          </div>
          <div className="column">
            <div class="box">
              <p className="title has-text-centered">Réservations</p>
              <div className="columns is-flex is-justify-content-center is-flex-direction-column ">
                <button class="button mt-2" onClick={ConsultReservation}>
                  Consulter la liste des réservations
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {user && user.role === "superAdminOnly" && (
        <div className="tile is-parent">
          <article className="tile is-child box">
            <p className="title has-text-centered">Utilisateurs</p>
            <div className="columns is-flex is-justify-content-center is-flex-direction-column">
              <button class="button mt-2 " onClick={ConsultUser}>
                Consulter la liste des utilisateur
              </button>
              <button
                class="button mt-2 has-background-success"
                onClick={AddUser}
              >
                Ajouter un utilisateur
              </button>
            </div>
          </article>
        </div>
      )}
    </div>
  );
};

export default Pannel;
