import React from "react";

const FinalReservation = () => {
  return (
    <section className="section">
    <div className="container">
      <h1 className="title">Merci pour votre réservation !</h1>
      <p className="subtitle">
        Nous avons bien reçu votre paiement et confirmé votre réservation de véhicule.
      </p>
      <p>
        Un e-mail de confirmation vous a été envoyé avec tous les détails de votre réservation.
      </p>
      <p>
        Nous vous souhaitons un agréable trajet et nous sommes impatients de vous revoir bientôt !
      </p>
      <button className="button is-primary mt-4" onClick={() => window.location.href = '/'}>
        Retour à l'accueil
      </button>
    </div>
  </section>
  );
};

export default FinalReservation;
