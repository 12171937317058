import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import { faGasPump } from "@fortawesome/free-solid-svg-icons";
import { faSnowflake } from "@fortawesome/free-solid-svg-icons";
import { faPerson } from "@fortawesome/free-solid-svg-icons";
import Gear from "../assets/images/gear.png";

const VehicleCard = ({ vehicule }) => {
  return (
    <div className="card">
      <div className="card-image">
        <figure className="image is-4by3">
          <img
            src={`https://dev.ninoloc.fr/uploads/${vehicule.pictures}`}
            alt={`image ${vehicule.name}`}
          />
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title">{vehicule.name}</p>
          </div>
        </div>

        <div className="content">
            <h2 className="subtitle is-5">
                  à partir de {vehicule.price} € / jours
                </h2>
          <div className="columns">
                  <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center ">
                    <FontAwesomeIcon icon={faPerson} className="icon_card" />
                    {vehicule.number_places}
                  </div>
                  <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                    <FontAwesomeIcon icon={faGasPump} className="icon_card" />
                    {vehicule.type_fuel}
                  </div>
                  <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                    <FontAwesomeIcon icon={faIdCard} className="icon_card" />
                    {vehicule.age_drive_license}
                  </div>
                  <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                    <img src={Gear} alt="" className="icon_card" />
                    {vehicule.gear_type}
                  </div>
                  <div className="column is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
                    <FontAwesomeIcon icon={faSnowflake} className="icon_card" />
                    {vehicule.air_conditioning}
                  </div>
                </div>
                <Link to={`/vehicules/reservation/${vehicule.uuid}`} ><button className="button">Réserver ce véhicule</button></Link>

        </div>
      </div>
    </div>
  );
};

export default VehicleCard;
