import React, { useEffect, useState,  } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import "../assets/styles/Stripe.css";
import diners from "../assets/images/diners-fbcbd3360f8e3f629cdaa80e93abdb8b (1).svg";
import mastercard from "../assets/images/mastercard-4d8844094130711885b5e41b28c9848f.svg";
import visa from "../assets/images/visa-729c05c240c4bdb47b03ac81d9945bfe.svg";
import jcb from "../assets/images/jcb-271fd06e6e7a2c52692ffa91a95fb64f.svg";
import discover from "../assets/images/discover-ac52cd46f89fa40a29a0bfb954e33173.svg";
import defaultCardImage from "../assets/images/card.svg";
import cryptoRandomString from "crypto-random-string";


const CheckoutForm = ({
  totalAmount,
  email,
  name,
  vehiculeId,
  startDate,
  startTime,
  endTime,
  endDate,
  vehicule_name
}) => {
  const stripe = useStripe();
  const element = useElements();
  const [cardBrand, setCardBrand] = useState("default");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [CardNumberElementEmpty, setCardNumberElementEmpty] = useState(true);
  const [CardExpiryElementEmpty, setCardExpiryElementEmpty] = useState(true);
  const [CardCvcElementEmpty, setCardCvcElementEmpty] = useState(true);
  const [referenceCommande , setReferenceCommande] = useState("");

  const navigate = useNavigate();

  const handleFocus = () => {
    setErrorMessage(null);
    setSuccessMessage(null);
  };

  
  useEffect(() => {
    const codeCommande = cryptoRandomString({ length: 6, type: "alphanumeric" });
    setReferenceCommande(codeCommande);
    console.log("codeCommande");
    console.log(codeCommande);
  }, []);

  useEffect(() => {
    if (stripe && element) {
      const cardNumberElement = element.getElement(CardNumberElement);
      const cardExpiryElement = element.getElement(CardExpiryElement); // Corrigé ici
      const cardCvcElement = element.getElement(CardCvcElement); // Corrigé ici

      if (cardNumberElement) {
        cardNumberElement.on("change", (event) => {
          setCardBrand(event.brand);
          setCardNumberElementEmpty(event.empty);
        });
      }

      if (cardExpiryElement) {
        // Corrigé ici
        cardExpiryElement.on("change", (event) => {
          // Corrigé ici
          setCardExpiryElementEmpty(event.empty); // Corrigé ici
        });
      }

      if (cardCvcElement) {
        // Corrigé ici
        cardCvcElement.on("change", (event) => {
          // Corrigé ici
          setCardCvcElementEmpty(event.empty); // Corrigé ici
        });
      }
    }
  }, [stripe, element]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (CardNumberElementEmpty) {
      setErrorMessage("Veuillez saisir un numéro de carte valide.");
      return;
    }
    if (CardExpiryElementEmpty) {
      setErrorMessage("Veuillez saisir une date d'expiration valide.");
      return;
    }
    if (CardCvcElementEmpty) {
      setErrorMessage("Veuillez saisir un code CVC valide.");
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: element.getElement(CardNumberElement),
      billing_details: {
        email: email,
        name: name,
      },
    });
    if (!error) {
      try {
        const { id } = paymentMethod;
        const response = await axios.post("https://dev.ninoloc.fr/api/pay", {

          amount: totalAmount,
          id: id,
        });
        if (response.data.success) {
          // setSuccessMessage(
          //   "Votre paiement a été effectué avec succès ! Merci d'avoir choisi notre service de location. Un e-mail de confirmation vous sera envoyé avec les détails de votre réservation. Nous vous souhaitons une excellente expérience de conduite !"
          // );
          try {
            const AmountBdd = (totalAmount/100)
            // console.log(AmountBdd)
            const response = await axios.post(
              `https://dev.ninoloc.fr/api/reservations/create`,
              {
                referenceCommande : referenceCommande,
                startDate : startDate,
                startTime : startTime,
                endDate : endDate,
                endTime : endTime,
                totalAmount : AmountBdd,
                vehiculeId: vehiculeId,
                vehicule_name : vehicule_name,
                paymentStatus : "paid",
              }
            );
             navigate("/remerciement" )
              console.log(' ok');
              
          } catch (error) {
            console.log('reservation not ok');
          }
        }
      } catch (error) {
        setErrorMessage(error.response.data.message);
        // setErrorMessage("Une erreur s'est produite lors du paiement. Veuillez réessayer.");
      }
    } else {
      setErrorMessage(
        "Une erreur s'est produite lors du paiement. Veuillez réessayer."
      );
    }
  };

  const cardImage = (brand) => {
    switch (brand) {
      case "visa":
        return visa;
      case "mastercard":
        return mastercard;
      case "diners":
        return diners;
      case "jcb":
        return diners;
      case "amex":
        return diners;
      default:
        return defaultCardImage;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="container mt-6">
      <div className="container">
        <div className="">Numéro de carte</div>
        <div className="container is-flex is-align-items-flex-end">
          <img
            src={cardImage(cardBrand)}
            alt="card logo"
            style={{ width: "50px", marginRight: "10px" }}
          />
          <CardNumberElement
            id="inputCard"
            className="mt-4"
            onFocus={handleFocus}
          />
        </div>
      </div>

      <div className="container mt-2">
        <div className="">Date d'expiration</div>
        <CardExpiryElement
          id="inputCard"
          className="mt-4"
          onFocus={handleFocus}
        />
      </div>

      <div className="container mt-2">
        <div className="">CVC</div>
        <CardCvcElement id="inputCard" className="mt-4" onFocus={handleFocus} />
      </div>

      <button style={styles.payButton} className="mt-4">
        Payer
      </button>
      {errorMessage && <div className="has-text-danger">{errorMessage}</div>}
      {successMessage && (
        <div className="has-text-success">{successMessage}</div>
      )}
    </form>
  );
};

const styles = {
  cardElement: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#f8f8f8",
    marginBottom: "15px",
  },
  payButton: {
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    padding: "12px 20px",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.2s",
  },
};

export default CheckoutForm;
